import React from "react";
import { TouchableOpacity, Image } from "react-native";
import { Box, Text } from "../../../components";
import { StripeProduct } from "../types";
import { useNavigate } from "react-router-dom";

interface SubscriptionCardProps {
  product: StripeProduct;
  subscriptionId: string;
}

export const SubscriptionCard = ({
  product,
  subscriptionId,
}: SubscriptionCardProps) => {
  const navigate = useNavigate();

  const { images, name, description } = product;

  return (
    <TouchableOpacity
      onPress={() => {
        navigate(`/user/subscription-details/${subscriptionId}`);
      }}
    >
      <Box
        padding="m"
        borderWidth={1}
        borderColor="layoutDivider"
        borderRadius="m"
        backgroundColor="backgroundSecondary"
        flexDirection="row"
        marginTop="m"
      >
        {images?.[0] && (
          <Image
            source={{ uri: images[0] }}
            style={{ height: 100, width: 100, borderRadius: 12 }}
          />
        )}

        <Box flex={1} paddingLeft="s" paddingTop="xs">
          <Text variant="h3">{name}</Text>
          <Text variant="body" color="textSubdued">
            {description}
          </Text>
          <Text variant="h6" textAlign="right" marginTop="l">
            Active subscription
          </Text>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
