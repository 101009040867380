import { useCallback, useEffect } from "react";
import {
  OrderItem,
  orderToAmountBreakdowns,
} from "@beinatlanda/zeppelin-shared";

import { useLocalOrder } from "../../../../hooks/useLocalOrder";
import { useNavigate, useParams } from "react-router-dom";

function useOrderScreen() {
  const { vendorId } = useParams();
  const { order, cancelItemInLocalOrder } = useLocalOrder(vendorId);

  const navigate = useNavigate();

  useEffect(() => {
    if (!order) {
      navigate(`/table-order/${vendorId}`, { replace: true });
    }
  }, [navigate, order, vendorId]);

  const orderedItems = order?.items;

  const userItemsMap = orderedItems?.reduce((acc, curr) => {
    const orderedById = curr.orderedBy.id;
    if (!acc[orderedById]) {
      return { ...acc, [orderedById]: [curr] };
    }
    return { ...acc, [orderedById]: [...acc[orderedById], curr] };
  }, {} as { [id: string]: OrderItem[] });

  const sections =
    userItemsMap &&
    Object.values(userItemsMap).map((items) => {
      const {
        orderedBy: { id, name, photo, userType },
      } = items[0];
      return {
        id,
        name,
        photo,
        userType,
        data: items,
      };
    });

  const itemCount = orderedItems?.length || 0;

  const { itemsSubtotalAmount } = orderToAmountBreakdowns({
    ...order,
    id: "temp_id",
  });

  const cancelItem = useCallback(
    (item: OrderItem) => {
      try {
        if (!order) return;

        cancelItemInLocalOrder(item);
      } catch (e) {
        console.log("cancel order error", e);
      }
    },
    [cancelItemInLocalOrder, order]
  );

  return { sections, itemCount, orderTotal: itemsSubtotalAmount, cancelItem };
}

export default useOrderScreen;
