import { useQuery } from "@tanstack/react-query";
import { Api } from "../../../api";

export const useUserSubscriptionDetails = (subscriptionId: string) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["user-subscription", subscriptionId],
    queryFn: () => Api.getUserSubscriptionDetails(subscriptionId),
  });

  return { data, isLoading, error };
};
