import React, { ReactElement } from 'react'
import { Image } from 'react-native'
import {
  useRestyle,
  createRestyleComponent,
  createVariant,
  VariantProps,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from '@shopify/restyle'

import { Theme } from '../theme'
import Box from './Box'

const containerRestyleFunctions = [spacing, layout, backgroundColor, border]
type Props = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    photo?: string | null
    testID?: string
  }

const Component = (props: Props): ReactElement => {
  const { photo, testID, ...rest } = props

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest })

  if (!photo) return <Box />

  return (
    <Box testID={testID} {...containerProps}>
      <Image
        style={{ width: '100%', height: '100%' }}
        source={{
          uri: photo,
        }}
        resizeMode="cover"
      />
    </Box>
  )
}

const Avatar = createRestyleComponent<VariantProps<Theme, 'avatarVariants'> & Props, Theme>(
  [...containerRestyleFunctions, createVariant({ themeKey: 'avatarVariants' })],
  Component,
)

export default Avatar
