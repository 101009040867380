import React, { useCallback, useState } from "react";
import { FlatList, ListRenderItem, TouchableOpacity } from "react-native";

import {
  Avatar,
  BottomModal,
  BottomTabBar,
  Box,
  ButtonIcon,
  Text,
} from "../../../components";
import { useFeed } from "../hooks/useFeed";
import { FeedItemCard } from "../components/FeedItemCard";
import { FeedItem } from "@beinatlanda/zeppelin-shared";
import { auth } from "../../../services";
import { useNavigate } from "react-router-dom";
import { EmailAuthModal } from "../../auth/screens/EmailAuthModal/EmailAuthModal";

function Feed() {
  const navigate = useNavigate();

  const isAuthenticated = !!auth.currentUser?.uid;
  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const { feedData } = useFeed();

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const renderFeedItem: ListRenderItem<FeedItem> = ({ item }) => {
    return <FeedItemCard item={item} />;
  };

  return (
    <>
      <Box flex={1} paddingTop="m" backgroundColor="backgroundPrimary">
        <Box
          flexDirection="row"
          alignItems="center"
          paddingHorizontal="m"
          paddingTop="xs"
          justifyContent="space-between"
        >
          <Text variant="h1">Feed</Text>
          {!currentUserProfilePhoto?.length ? (
            <ButtonIcon
              icon="user"
              backgroundColor="backgroundSecondary"
              onPress={openMenu}
            />
          ) : (
            <TouchableOpacity onPress={openMenu}>
              <Avatar photo={currentUserProfilePhoto} variant="large" />
            </TouchableOpacity>
          )}
        </Box>
        <Box paddingTop="s">
          <FlatList data={feedData} renderItem={renderFeedItem} />
        </Box>
        {!isAuthenticated && (
          <Text variant="body" paddingTop="l" paddingHorizontal="m">
            Please sign in to access the feed
          </Text>
        )}
      </Box>
      <BottomTabBar activeTab="feed" />

      {isAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={closeMenu}
          onPostSignUp={closeMenu}
        />
      )}
    </>
  );
}

export default Feed;
