import { useEffect, useState } from "react";
import { FeedItem } from "@beinatlanda/zeppelin-shared";
import { Feed } from "../../../services";
export const useFeed = () => {
  const [feedData, setFeedData] = useState<FeedItem[]>();

  useEffect(() => {
    const subscriber = Feed.subscribeFeed(setFeedData);

    return () => {
      subscriber();
    };
  }, []);

  return {
    feedData,
  };
};
