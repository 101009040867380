import { useQuery } from "@tanstack/react-query";
import { Api } from "../../../api";

export const useProductDetails = (productId: string) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["product-details", productId],
    queryFn: () => Api.getProductDetails(productId),
  });

  return { data, isLoading, error };
};
