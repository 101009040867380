import { useEffect, useState } from "react";
import { User as UserType } from "@beinatlanda/zeppelin-shared";

import { User } from "../services";

const useUser = (userId?: string) => {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    if (!userId) return;

    const subscriber = User.subscribeUser(userId, setUser);

    return () => {
      subscriber();
    };
  }, [userId]);

  return { user };
};

export default useUser;
