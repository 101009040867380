import React from "react";
import { Box, Text } from "../../../components";
import { Image } from "react-native";
import dayjs from "dayjs";
import { FeedItem } from "@beinatlanda/zeppelin-shared";

interface FeedItemCardProps {
  item: FeedItem;
}

export const FeedItemCard = ({ item }: FeedItemCardProps) => {
  const { title, content, image, createdAt, senderName, senderImage } = item;

  return (
    <Box
      backgroundColor="backgroundPrimary"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="layoutDivider"
      marginVertical="s"
      overflow="hidden"
    >
      <Box
        flexDirection="row"
        alignItems="center"
        paddingHorizontal="s"
        paddingVertical="s"
      >
        <Image
          source={{ uri: senderImage }}
          style={{ width: 30, height: 30, borderRadius: 30 }}
        />
        <Text variant="h5" marginLeft="s">
          {senderName}
        </Text>
      </Box>
      {!!image && (
        <Image source={{ uri: image }} style={{ width: "100%", height: 150 }} />
      )}
      <Box padding="s">
        <Text variant="h5" paddingTop="s">
          {title}
        </Text>
        <Text variant="body" paddingTop="xs">
          {content}
        </Text>
        <Text
          variant="meta"
          color="textSubdued"
          paddingTop="s"
          textAlign="right"
        >
          {dayjs(createdAt.toDate()).format("dddd, MMMM D, YYYY h:mm A")}
        </Text>
      </Box>
    </Box>
  );
};
