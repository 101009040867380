import { useEffect, useState } from 'react'
import { MenuItem } from '@beinatlanda/zeppelin-shared'

import { Vendors } from '../services'

function useOfferingItemDetails(itemId: string, vendorId: string) {
  const [itemDetails, setItemDetails] = useState<MenuItem | null>(null)

  useEffect(() => {
    const subscriber = Vendors.subscribeGetVendorOfferingItem(itemId, vendorId, item => {
      setItemDetails(item)
    })
    return () => {
      subscriber()
    }
  }, [itemId, vendorId])

  return {
    itemDetails,
  }
}

export default useOfferingItemDetails
