import { Table } from "@beinatlanda/zeppelin-shared";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "./firebase/firebase";

export const Tables = {
  /**
   * Get tables info subscription. It returns the subscriber to stop listening to changes.
   *
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeTables(
    vendorId: string,
    observer: (data: Table[]) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      query(collection(db, "vendors", vendorId, "tables"), orderBy("name")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as Table;
        });
        observer(data);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('vendors')
    //   .doc(vendorId)
    //   .collection('tables')
    //   .orderBy('name')
    //   .onSnapshot(snapshot => {
    //     const data = snapshot.docs.map(doc => {
    //       return { id: doc.id, ...doc.data() } as Table
    //     })
    //     observer(data)
    //   }, onError)
    return subscriber;
  },
  /**
   * GetTableInfo - get individual table object
   *
   * @param vendorId vendor id
   * @param tableId table id
   */
  async getTableInfo(vendorId: string, tableId: string) {
    const response = await getDoc(
      doc(db, "vendors", vendorId, "tables", tableId)
    );
    // firestore().collection('vendors').doc(vendorId).collection('tables').doc(tableId).get()

    if (!response.exists()) {
      return null;
    }

    return { id: response.id, ...response.data() } as Table;
  },
};
