import { createTheme, useTheme } from "@shopify/restyle";

const palette = {
  purple: "#6144F5",
  green: "#5FC599",
  yellow: "#F6D150",
  red: "#D95988",
  lightPurple: "#F8FAFF",
  white: "#FFF",
  offWhite: "#FCFCFC",
  black: "#000",
  darkGrey: "#808080",
  lightGrey: "#EFEFEF",
  modalBackground: "rgba(0,0,0,0.5)",
  darkBlue: "#121420",
};

const theme = createTheme({
  colors: {
    primary: palette.purple,
    secondary: palette.green,
    tertiary: palette.yellow,
    danger: palette.red,

    backgroundPrimary: palette.white,
    backgroundSecondary: palette.offWhite,
    backgroundContrast: palette.darkBlue,
    backgroundLightPrimary: palette.lightPurple,

    textPrimary: palette.black,
    textAlt: palette.white,
    textSubdued: palette.darkGrey,

    layoutDivider: palette.lightGrey,

    modalBackground: palette.modalBackground,
  },
  spacing: {
    xxs: 2,
    xs: 5,
    s: 10,
    m: 15,
    l: 25,
    xl: 40,
  },
  borderRadii: {
    xs: 1,
    s: 2,
    m: 4,
    l: 8,
    xl: 20,
    xxl: 25,
    xxxl: 1000,
  },
  breakpoints: {
    phone: 0,
    tablet: 768,
  },
  zIndices: { s: 1, m: 4, l: 8 },
  textVariants: {
    h1: {
      fontSize: 28,
      fontWeight: "bold",
      color: "textPrimary",
    },
    h2: {
      fontSize: 22,
      fontWeight: "bold",
      color: "textPrimary",
    },
    h3: {
      fontSize: 18,
      fontWeight: "bold",
      color: "textPrimary",
    },
    h4: {
      fontSize: 16,
      fontWeight: "bold",
      color: "textPrimary",
    },
    h5: {
      fontSize: 14,
      fontWeight: "bold",
      color: "textPrimary",
    },
    h6: {
      fontSize: 14,
      fontWeight: "normal",
      color: "textPrimary",
    },
    body: {
      fontSize: 13,
      color: "textPrimary",
    },
    meta: {
      fontSize: 10,
      color: "textPrimary",
    },
  },
  buttonIconVariants: {
    defaults: {
      height: 40,
      width: 40,
      borderRadius: "xl",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "backgroundPrimary",
    },
    large: {
      height: 50,
      width: 50,
      borderRadius: "xxl",
    },
    xSmall: {
      height: 15,
      width: 15,
      borderRadius: "xxl",
    },
  },
  buttonMainVariants: {
    defaults: {
      height: 50,
      paddingHorizontal: "m",
      borderRadius: "m",
      backgroundColor: "primary",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    outline: {
      borderWidth: 1,
      borderColor: "primary",
      backgroundColor: "backgroundPrimary",
    },
  },
  avatarVariants: {
    defaults: {
      height: 30,
      width: 30,
      borderRadius: "xxxl",
      overflow: "hidden",
      borderWidth: 1,
      borderColor: "backgroundSecondary",
    },
    small: {
      height: 20,
      width: 20,
    },
    large: {
      height: 40,
      width: 40,
    },
  },
  // chatVariants: {
  //   defaults: {
  //     width: '70%',
  //     paddingHorizontal: 'l',
  //     paddingVertical: 'm',
  //     marginVertical: 's',
  //   },
  //   self: {
  //     backgroundColor: 'secondary',
  //     borderTopRightRadius: 'm',
  //     borderTopLeftRadius: 'm',
  //     borderBottomLeftRadius: 'm',
  //     alignSelf: 'flex-end',
  //   },
  //   other: {
  //     backgroundColor: 'background4',
  //     borderTopRightRadius: 'm',
  //     borderTopLeftRadius: 'm',
  //     borderBottomRightRadius: 'm',
  //     alignSelf: 'flex-start',
  //   },
  // },
  // textInputVariants: {
  //   defaults: {
  //     width: '100%',
  //     height: 50,
  //     fontSize: 14,
  //     color: 'textPrimary',
  //     backgroundColor: 'background4',
  //     borderRadius: 's',
  //     paddingHorizontal: 'm',
  //   },
  // },
  // selectVariants: {
  //   defaults: {
  //     height: 50,
  //     paddingHorizontal: 'l',
  //     borderRadius: 's',
  //     backgroundColor: 'buttonBackground',
  //     flexDirection: 'row',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //   },
  // },
});

export type Theme = typeof theme;
export const useAppTheme = () => useTheme<Theme>();
export default theme;
