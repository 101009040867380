import React, { useCallback, useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native";

import {
  Avatar,
  BottomModal,
  BottomTabBar,
  Box,
  ButtonIcon,
  Text,
} from "../../../../components";

import { useAppTheme } from "../../../../theme";
import { auth } from "../../../../services";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { useUserSubscriptions } from "../../hooks/useUserSubscriptions";
import { SubscriptionCard } from "../../components/SubscriptionCard";
import { BulkProductCard } from "../../components/BulkProductCard";

const AppStoreButton = require("../../../../assets/download_on_the_app_store.jpeg");
const PlayStoreButton = require("../../../../assets/get_it_on_play_store.jpeg");

export const Subscriptions = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewSubscription = searchParams.get("new_subscription");
  const isAuthenticated = !!auth.currentUser?.uid;

  const { data, isLoading, error } = useUserSubscriptions();

  const { spacing } = useAppTheme();

  const [showMenu, setShowMenu] = useState(false);
  const [showDownloadMobileModal, setShowDownloadMobileModal] = useState(false);

  useEffect(() => {
    if (isNewSubscription) {
      // Hidin mobil app modal for now
      // setShowDownloadMobileModal(true);
    }
  }, [isNewSubscription]);

  // const { user } = useUser(auth.currentUser?.uid);

  // const isZeppelinMember = user?.activeSubscriptions?.includes(
  //   "zeppelin-membership"
  // );

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  // // Temporary: Add zeppelin-membership to user active subscriptions
  // useEffect(() => {
  //   if (!!isNewMembership || !auth.currentUser?.uid) return;

  //   User.updateUser(auth.currentUser?.uid, {
  //     activeSubscriptions: ["zeppelin-membership"],
  //   });
  //   setSearchParams({});
  // }, [isNewMembership, setSearchParams]);

  return (
    <>
      <Box flex={1} paddingTop="m" backgroundColor="backgroundPrimary">
        <Box
          flexDirection="row"
          alignItems="center"
          paddingHorizontal="m"
          paddingTop="xs"
          justifyContent="space-between"
        >
          <Text variant="h1">My subscriptions</Text>
          {!currentUserProfilePhoto?.length ? (
            <ButtonIcon
              icon="user"
              backgroundColor="backgroundSecondary"
              onPress={openMenu}
            />
          ) : (
            <TouchableOpacity onPress={openMenu}>
              <Avatar photo={currentUserProfilePhoto} variant="large" />
            </TouchableOpacity>
          )}
        </Box>
        <ScrollView
          contentContainerStyle={{
            paddingHorizontal: spacing.m,
            paddingTop: spacing.xl,
          }}
        >
          {isLoading && <ActivityIndicator />}
          {/* {isZeppelinMember ? (
            <ZeppelinSubscriptionCard />
          ) : (
            <>
              <Text
                variant="body"
                textAlign="center"
                paddingTop="l"
                color="textSubdued"
              >
                You currently don't have any active subscriptions.
              </Text>
              <ButtonMain
                variant="outline"
                label="Join Zeppelin membership"
                color="primary"
                marginTop="m"
                onPress={() => {
                  navigate("/zeppelin-membership");
                }}
              />
            </>
          )} */}

          {data?.bulkProductPurchases
            ?.filter((i) => !i.isAppOnly)
            ?.map((product) => {
              return <BulkProductCard product={product} />;
            })}

          {data?.subscriptions?.map((sub) => (
            <SubscriptionCard
              key={sub?.id}
              product={sub.plan.product}
              subscriptionId={sub.id}
            />
          ))}
        </ScrollView>
      </Box>
      <BottomTabBar activeTab="subscriptions" />

      <BottomModal
        open={showDownloadMobileModal}
        onCloseModal={() => {
          setShowDownloadMobileModal(false);
        }}
      >
        <Box paddingBottom="l">
          <Text variant="h3" paddingVertical="m">
            It's better on our app!
          </Text>
          <Text variant="body" paddingVertical="m" textAlign="center">
            Download our mobile app to always have quick access to your Zeppelin
            wallet, receive notifications and enjoy a better overall experience.
          </Text>
          <TouchableOpacity
            style={{ paddingTop: 10 }}
            onPress={() => {
              window.open(
                "https://apps.apple.com/gb/app/zeppelin/id1532928443",
                "_blank"
              );
            }}
          >
            <Image
              source={AppStoreButton}
              style={{ height: 51 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{ paddingTop: 10 }}
            onPress={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.meetZeppelin.customerApp",
                "_blank"
              );
            }}
          >
            <Image
              source={PlayStoreButton}
              style={{ height: 50 }}
              resizeMode="contain"
            />
          </TouchableOpacity>
        </Box>
      </BottomModal>

      {isAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                console.log(
                  process.env.REACT_APP_STRIPE_BILLING_CUSTOMER_PORTAL_LINK
                );
                window.open(
                  process.env.REACT_APP_STRIPE_BILLING_CUSTOMER_PORTAL_LINK
                );
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Manage subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={closeMenu}
          onPostSignUp={closeMenu}
        />
      )}
    </>
  );
};
