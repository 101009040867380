import { useCallback, useState } from "react";
import { Auth } from "../../../../services";
import { Keyboard } from "react-native";
import { postSignUp as postSignUpAction } from "../../actions/postSignUp";
import { useNavigate } from "react-router-dom";

interface useSignUpScreenProps {
  onPostSignUp?: () => void;
}

export const useSignUpScreen = (props?: useSignUpScreenProps) => {
  const { onPostSignUp } = props;

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    try {
      Keyboard.dismiss();
      /**
       * Mising fields check
       */
      if (
        !name.length ||
        !email.length ||
        !password.length ||
        !confirmPassword.length
      ) {
        setError("All fields are required");
        return;
      }
      /**
       * Passwords not matching check
       */
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        setPassword("");
        setConfirmPassword("");
        return;
      }

      setError(null);

      await Auth.signUpWithEmail(email, password);

      await postSignUpAction(name, email);

      await onPostSignUp?.();

      setLoading(false);
      //TODO: add user analytics tracking
    } catch (e) {
      // const firebaseAuthError = e as FirebaseAuthTypes.NativeFirebaseAuthError
      if (e.code === "auth/email-already-in-use") {
        setError("That email address is already in use!");
        return;
      }

      if (e.code === "auth/invalid-email") {
        setError("That email address is invalid!");
        return;
      }

      if (e.code === "auth/weak-password") {
        setError("Please choose a stronger password");
        return;
      }
      setError(e.message);
      setLoading(false);
    }
  }, [confirmPassword, email, name, onPostSignUp, password]);

  const onLogIn = () => {
    navigate("EmailLogIn");
  };

  return {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    onSubmit,
    onLogIn,
    error,
    loading,
  };
};
