import React from "react";
import { TouchableOpacity, Image } from "react-native";
import { Box, Text } from "../../../components";
import { BulkProductPurchase } from "../types";
import { useNavigate } from "react-router-dom";

interface BulkProductCardProps {
  product: BulkProductPurchase;
}

export const BulkProductCard = ({ product }: BulkProductCardProps) => {
  const navigate = useNavigate();

  const { id, photo, name, description, itemsUsed, itemAllowance } = product;

  return (
    <TouchableOpacity
      onPress={() => {
        navigate(`/user/bulk-purchase/${id}`);
      }}
    >
      <Box
        padding="m"
        borderWidth={1}
        borderColor="layoutDivider"
        borderRadius="m"
        backgroundColor="backgroundSecondary"
        flexDirection="row"
        marginTop="m"
      >
        {!!photo && (
          <Image
            source={{ uri: photo }}
            style={{ height: 100, width: 100, borderRadius: 12 }}
          />
        )}

        <Box flex={1} paddingLeft="s" paddingTop="xs">
          <Text variant="h4" color="primary">
            Bulk
          </Text>
          <Text variant="h3">{name}</Text>
          <Text variant="body" color="textSubdued">
            {description}
          </Text>
          <Text variant="h6" textAlign="right" marginTop="l">
            {`${itemAllowance - itemsUsed} items left`}
          </Text>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
