import {
  createUserWithEmailAndPassword,
  deleteUser,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  UserProfile,
} from "firebase/auth";
import { Alert } from "react-native";
import { auth } from "./firebase/firebase";
import { User } from "./User";

export const Auth = {
  /**
   * Signs up user using email and password
   *
   * @param email user email
   * @param password new password
   */
  async signUpWithEmail(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  },
  /**
   * Logs in user using email and password
   *
   * @param email user email
   * @param password account password
   */
  async signInWithEmail(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  },
  // /**
  //  * Signs in user using social provider credential (apple, google, etc.)
  //  *
  //  * @param credential
  //  */
  // async signInWithCredential(credential: FirebaseAuthTypes.AuthCredential) {
  //   return auth().signInWithCredential(credential);
  // },
  /**
   * Signs in user anonimoously
   *
   */
  async signInAnonimously() {
    return signInAnonymously(auth);
  },
  /**
   * Updates auth user profile data: displayName, photoURL
   *
   * @param userData
   */
  async updateUserProfile(userProfileData: Partial<UserProfile>) {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    return updateProfile(currentUser, userProfileData);
  },
  /**
   * Logs user out
   */
  async logOut() {
    return signOut(auth);
  },
  /**
   * Delete user account
   */
  async deleteAccount() {
    const currentUser = auth.currentUser;

    if (!currentUser) return;

    await User.deleteUser(currentUser.uid);
    await deleteUser(currentUser);

    Alert.alert("User deleted", "Your user account has been deleted");
  },
};
