import React, { ReactElement } from "react";
import { Plus, Minus, X, ArrowLeft, Share, User } from "react-feather";
import {
  useRestyle,
  createRestyleComponent,
  createVariant,
  VariantProps,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
  position,
  PositionProps,
  useTheme,
} from "@shopify/restyle";

import theme, { Theme } from "../theme";
import { TouchableOpacity } from "react-native";

const containerRestyleFunctions = [
  spacing,
  layout,
  backgroundColor,
  border,
  position,
];

export type Icon = "plus" | "minus" | "x" | "arrow-left" | "share" | "user";

const iconSizes = {
  xs: 10,
  s: 15,
  m: 20,
  l: 24,
};

type Props = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  PositionProps<Theme> &
  LayoutProps<Theme> & {
    onPress?: () => void;
    icon: Icon;
    iconSize?: keyof typeof iconSizes;
    iconColor?: keyof typeof theme.colors;
    testID?: string;
  };

const Component = (props: Props): ReactElement => {
  const { colors } = useTheme<Theme>();

  const {
    onPress,
    icon,
    testID,
    iconSize = "m",
    iconColor = "textPrimary",
    ...rest
  } = props;

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest });

  const iconSizeValue = iconSizes[iconSize];
  const iconColorValue = colors[iconColor];

  return (
    <TouchableOpacity onPress={onPress} testID={testID} {...containerProps}>
      {icon === "plus" && <Plus size={iconSizeValue} color={iconColorValue} />}
      {icon === "minus" && (
        <Minus size={iconSizeValue} color={iconColorValue} />
      )}
      {icon === "x" && <X size={iconSizeValue} color={iconColorValue} />}
      {icon === "arrow-left" && (
        <ArrowLeft size={iconSizeValue} color={iconColorValue} />
      )}
      {icon === "share" && (
        <Share size={iconSizeValue} color={iconColorValue} />
      )}
      {icon === "user" && <User size={iconSizeValue} color={iconColorValue} />}
    </TouchableOpacity>
  );
};

const ButtonIcon = createRestyleComponent<
  VariantProps<Theme, "buttonIconVariants"> & Props,
  Theme
>(
  [
    ...containerRestyleFunctions,
    createVariant({ themeKey: "buttonIconVariants" }),
  ],
  Component
);

export type ButtonIconProps = VariantProps<Theme, "buttonIconVariants"> & Props;

export default React.memo(ButtonIcon);
