import React, { useCallback } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import { User } from "react-feather";
import { Box, Header, Text } from "../../../../components";

import { useAppTheme } from "../../../../theme";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../services";

const zeppelinTileImage = require("../../../../assets/ZeppelinTile.png");

export const MembershipProofDisplay = () => {
  const navigate = useNavigate();

  const { colors, spacing } = useAppTheme();

  const currentUserName = auth.currentUser?.displayName || null;
  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box style={{ flex: 1, backgroundColor: colors.primary }}>
      <Header onBackPress={goBack} transparent showBorder={false} />
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: spacing.m,
          paddingTop: spacing.l,
          flexGrow: 1,
          alignItems: "center",
        }}
        bounces={false}
      >
        <Image
          source={zeppelinTileImage}
          style={{
            height: 70,
            width: 70,
            borderWidth: 1,
            borderColor: colors.layoutDivider,
            borderRadius: spacing.m,
            alignSelf: "center",
          }}
        />
        <Text variant="h1" color="textAlt" marginTop="l" textAlign="center">
          Zeppelin member
        </Text>
        {!!currentUserProfilePhoto ? (
          <Image
            source={{ uri: currentUserProfilePhoto }}
            style={{
              height: 200,
              width: 200,
              borderRadius: 150,
              alignSelf: "center",
              marginTop: spacing.l,
            }}
          />
        ) : (
          <TouchableOpacity
            onPress={() => {
              navigate("/EditProfilePhoto");
            }}
          >
            <Box
              height={200}
              width={200}
              borderRadius="xxxl"
              borderWidth={2}
              borderColor="textAlt"
              alignSelf="center"
              justifyContent="center"
              alignItems="center"
              marginTop="l"
            >
              <User size={40} color={colors.textAlt} />
              <Text variant="h5" color="textAlt" paddingTop="s">
                Add photo
              </Text>
            </Box>
          </TouchableOpacity>
        )}
        <Text variant="h6" paddingTop="l" color="textAlt" textAlign="center">
          Name
        </Text>
        <Text variant="h2" color="textAlt" textAlign="center">
          {currentUserName}
        </Text>
        <Text
          variant="body"
          color="textAlt"
          paddingTop="s"
          textAlign="center"
          style={{ marginTop: "auto", marginBottom: 20 }}
        >
          Show this screen to a member of staff to prove your membership.
        </Text>
      </ScrollView>
    </Box>
  );
};
