import React from "react";
import { Box, Divider, Text } from ".";

interface AdditionalChargesProps {
  subtotal?: number;
  discountPercentage?: number;
  discountTotalAmount?: number;
  serviceChargePercentage?: number;
  serviceCharge?: number;
  processingCost?: number;
  tipPercentage?: number;
  tip?: number;
}

function AdditionalCharges(props: AdditionalChargesProps) {
  const {
    subtotal,
    discountPercentage,
    discountTotalAmount,
    serviceCharge,
    serviceChargePercentage,
    processingCost,
    tipPercentage,
    tip,
  } = props;
  return (
    <Box backgroundColor="backgroundSecondary">
      <Divider marginBottom="s" />
      {!!subtotal && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          paddingVertical="xs"
          paddingHorizontal="m"
        >
          <Text variant="body" color="textSubdued">
            Subtotal
          </Text>
          <Text variant="body">{`£${(subtotal / 100).toFixed(2)}`}</Text>
        </Box>
      )}
      {!!discountPercentage && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          paddingVertical="xs"
          paddingHorizontal="m"
        >
          <Text variant="body" color="textSubdued">{`Discount ${
            discountPercentage ? `(${discountPercentage}%)` : ""
          }`}</Text>
          <Text variant="body">{`- £${(discountTotalAmount / 100).toFixed(
            2
          )}`}</Text>
        </Box>
      )}
      {!!serviceCharge && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          paddingVertical="xs"
          paddingHorizontal="m"
        >
          <Text variant="body" color="textSubdued">{`Service charge ${
            serviceChargePercentage ? `(${serviceChargePercentage}%)` : ""
          }`}</Text>
          <Text variant="body">{`£${(serviceCharge / 100).toFixed(2)}`}</Text>
        </Box>
      )}
      {!!processingCost && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          paddingVertical="xs"
          paddingHorizontal="m"
        >
          <Text variant="body" color="textSubdued">
            Processing charge
          </Text>
          <Text variant="body">{`£${(processingCost / 100).toFixed(2)}`}</Text>
        </Box>
      )}
      {!!tip && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          paddingVertical="xs"
          paddingHorizontal="m"
        >
          <Text variant="body" color="textSubdued">{`Tip ${
            tipPercentage ? `(${tipPercentage.toFixed(2)}%)` : ""
          }`}</Text>
          <Text variant="body">{`£${(tip / 100).toFixed(2)}`}</Text>
        </Box>
      )}
      <Divider marginTop="s" />
    </Box>
  );
}

export default React.memo(AdditionalCharges);
