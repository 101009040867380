import { useTheme } from "@shopify/restyle";

import { Image, Pressable } from "react-native";

import { Box, Text } from ".";
import { Theme } from "../theme";

interface OfferingListItemProps {
  id: string;
  name: string;
  description?: string;
  price?: number;
  discountedPrice?: number;
  photo?: string | null;
  count?: number;
  onPress?: (id: string) => void;
}

function OfferingListItem(props: OfferingListItemProps) {
  const {
    id,
    name,
    description,
    price,
    discountedPrice,
    photo,
    count,
    onPress,
  } = props;

  const { borderRadii } = useTheme<Theme>();

  return (
    <Pressable onPress={() => onPress && onPress(id)} disabled={!onPress}>
      <Box padding="m" flexDirection="row">
        <Box flex={1} paddingVertical="xs">
          <Text variant="h6" numberOfLines={2}>
            {name}
          </Text>
          <Text variant="body" color="textSubdued" numberOfLines={2}>
            {description}
          </Text>
          <Box flexDirection="row">
            {!!price && (
              <Text
                variant={discountedPrice ? "h6" : "h5"}
                textDecorationLine={discountedPrice ? "line-through" : "none"}
              >{`£${(price / 100).toFixed(2)}`}</Text>
            )}
            {!!discountedPrice && (
              <Text variant="h5" paddingLeft="xs">{`£${(
                discountedPrice / 100
              ).toFixed(2)}`}</Text>
            )}
          </Box>
        </Box>
        <Box minWidth={20}>
          {!!photo && (
            <Image
              source={{ uri: photo }}
              style={{ height: 80, width: 80, borderRadius: borderRadii.m }}
              resizeMode="cover"
            />
          )}
          {count && (
            <Box
              height={22}
              width={22}
              backgroundColor="primary"
              justifyContent="center"
              alignItems="center"
              borderRadius="xs"
              position="absolute"
              top={0}
              right={0}
            >
              <Text variant="h5" color="textAlt">
                {count}
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Pressable>
  );
}

export default OfferingListItem;
