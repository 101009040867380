import React, { useCallback, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import {
  Avatar,
  BottomModal,
  BottomPanel,
  Box,
  ButtonIcon,
  ButtonMain,
  Text,
} from "../../../../components";
import { useAuthentication } from "../../../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../../services";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { useMutation } from "@tanstack/react-query";
import { Api } from "../../../../api";
import { useBulkProductDetails } from "../../hooks/useBulkProductDetails";
import { BulkProduct } from "../../types";
import { useBulkProductLanding } from "./useBulkProductLanding";
import { PaymentFormModal } from "../../../tableOrder/components/PaymentFormModal/PaymentFormModa";

const zeppelinLogo = require("../../../../assets/zeppelin_logo.png");

export function BulkProductLanding() {
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();

  const { data, isLoading } = useBulkProductDetails(productId, vendorId);

  const {
    onPayHandler,
    paymentSetupInProgress,
    clientSecret,
    paymentSuccessUrl,
    dismissPaymentClientSecret,
  } = useBulkProductLanding({ product: data });

  const { isLoaded, userLoggedIn, isAnonimous } = useAuthentication();
  const isUserAuthenticated = isLoaded && userLoggedIn && !isAnonimous;

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const [showEmailAuth, setShowEmailAuth] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  if (isLoading || !data) return <ActivityIndicator />;

  const { id, price, itemAllowance, description, name, photo } =
    data as BulkProduct;

  return (
    <>
      <Box flex={1} backgroundColor="backgroundPrimary">
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Box
            paddingHorizontal="m"
            paddingTop="l"
            backgroundColor="backgroundLightPrimary"
            alignItems="center"
            paddingBottom="l"
          >
            {!currentUserProfilePhoto?.length ? (
              <ButtonIcon
                icon="user"
                backgroundColor="backgroundSecondary"
                onPress={openMenu}
                position="absolute"
                top={20}
                right={15}
              />
            ) : (
              <TouchableOpacity
                onPress={openMenu}
                style={{ position: "absolute", top: 20, right: 15 }}
              >
                <Avatar photo={currentUserProfilePhoto} variant="large" />
              </TouchableOpacity>
            )}
            <Image
              source={zeppelinLogo}
              style={{ height: 24, width: "100%" }}
              resizeMode="contain"
            />
            <Text variant="h1" fontSize={42} textAlign="center" paddingTop="xl">
              {name}
            </Text>

            <Image
              source={{ uri: photo }}
              style={{ height: 300, width: "100%", marginTop: 32 }}
            />
            <Text
              variant="body"
              fontWeight="300"
              fontSize={22}
              color="textSubdued"
              paddingTop="l"
              textAlign="center"
            >
              {description}
            </Text>
          </Box>
        </ScrollView>

        <EmailAuthModal
          visible={showEmailAuth}
          onClose={() => setShowEmailAuth(false)}
          onPostLogIn={onPayHandler}
          onPostSignUp={onPayHandler}
        />

        <BottomPanel
          content={{
            key: "Price:",
            value: `£${price / 100}`,
          }}
        >
          <ButtonMain
            label="Buy"
            loading={paymentSetupInProgress}
            onPress={() => {
              isUserAuthenticated ? onPayHandler() : setShowEmailAuth(true);
            }}
          />
          {!!isUserAuthenticated && (
            <ButtonMain
              color="textPrimary"
              backgroundColor="backgroundSecondary"
              label="My subscriptions"
              onPress={() => {
                navigate("/user/subscriptions");
              }}
              marginTop="xs"
            />
          )}
        </BottomPanel>
      </Box>

      {isUserAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                navigate("/user/feed");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Feed
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/subscriptions");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={closeMenu}
          onPostSignUp={closeMenu}
        />
      )}

      {clientSecret && (
        <PaymentFormModal
          key={"a"}
          clientSecret={clientSecret}
          successUrl={paymentSuccessUrl}
          closeModalHandler={dismissPaymentClientSecret}
        />
      )}
    </>
  );
}
