import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTableOrder } from "../../../../hooks";

export const usePaymentSuccessScreen = () => {
  const navigate = useNavigate();
  const { vendorId, orderId } = useParams();

  const { order } = useTableOrder(orderId);

  useEffect(() => {
    // Remove local order
    sessionStorage.removeItem(vendorId);
  }, [vendorId]);

  const downloadZeppelinHandler = useCallback(() => {
    const appStoreUrl = "https://apps.apple.com/gb/app/zeppelin/id1532928443";

    window.open(appStoreUrl);
  }, []);

  const newOrderHandler = useCallback(() => {
    navigate(`/table-order/${order?.referrerVendorId || vendorId}`);
  }, [navigate, order, vendorId]);

  return { order, downloadZeppelinHandler, newOrderHandler };
};
