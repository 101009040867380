import React from 'react'
import { Image, TouchableOpacity } from 'react-native'
import { Vendor } from '@beinatlanda/zeppelin-shared'

import { Box, Text } from '.'

interface VendorCardMainProps {
  vendor: Vendor
  onPress: (id: string) => void
}

function VendorCardMain(props: VendorCardMainProps) {
  const { vendor, onPress } = props
  const { name, photo, id, description, pricingCategory } = { ...vendor }

  return (
    <TouchableOpacity
      onPress={() => {
        onPress(id)
      }}>
      <Box paddingBottom="m">
        {!!photo && (
          <Image source={{ uri: photo }} style={{ height: 180, width: '100%', borderRadius: 5 }} resizeMode="cover" />
        )}
        <Box paddingVertical="s">
          <Text variant="h2">{name}</Text>
          <Box flexDirection="row" justifyContent="space-between" paddingTop="xxs">
            <Text variant="h6" color="textSubdued" numberOfLines={2}>
              {description}
            </Text>
            {!!pricingCategory && pricingCategory < 5 && (
              <Text variant="h6" color="textSubdued">
                {'£'.repeat(pricingCategory)}
              </Text>
            )}
          </Box>
          {/* <Text variant="body" color="textSubdued" paddingTop="xxs">
            0.6 miles away
          </Text> */}
        </Box>
      </Box>
    </TouchableOpacity>
  )
}

export default React.memo(VendorCardMain)
