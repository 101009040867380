import { PaymentMetadata, PaymentType } from "@beinatlanda/zeppelin-shared";
import { httpsCallable } from "firebase/functions";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { auth, functions, Orders } from "../../../../services";
import { useVendorDetails } from "../../../../hooks";
import { BulkProduct } from "../../types";
import { Api } from "../../../../api";

export function useBulkProductLanding({ product }: { product?: BulkProduct }) {
  const { vendorId } = useParams();

  const { vendor } = useVendorDetails(vendorId);

  const navigate = useNavigate();

  const [paymentSetupInProgress, setPaymentSetupInProgress] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const paymentSuccessUrl = `${window.location.origin}/user/subscriptions?new_subscription=true`;

  const dismissPaymentClientSecret = useCallback(() => {
    setClientSecret(null);
  }, []);

  const onPayHandler = async () => {
    setPaymentSetupInProgress(true);

    if (!product) return;

    if (!auth.currentUser) {
      window.alert("Missing user information");
      return;
    }

    const paymentMetadata = {
      id: uuidv4(),
      paymentType: "bulk",
      createdAt: new Date().toISOString(),
      vendorId: vendorId || null,
      vendorName: vendor?.name || null,
      vendorPhoto: vendor?.photo || null,
      userId: auth.currentUser.uid || null,
      userName: auth.currentUser.displayName || null,
      currencyCode: "GBP",
      productId: product?.id || null,
      productName: product?.name || null,
      productDescription: product?.description || null,
      productPhoto: product?.photo || null,
      productPrice: product?.price || null,
      productItemAllowance: product?.itemAllowance || null,
    };

    console.log({ paymentMetadata });

    try {
      const response = await Api.createBulkPaymentIntent(paymentMetadata);
      console.log({ response });
      const { paymentIntentClientSecret, paymentIntentId } = response;

      console.log({
        paymentIntentClientSecret,
        paymentIntentId,
      });

      setClientSecret(paymentIntentClientSecret);

      setPaymentSetupInProgress(false);
    } catch (error) {
      setPaymentSetupInProgress(false);
      console.log("createBulkpaymentIntent error", error);
    }
  };

  return {
    onPayHandler,
    paymentSetupInProgress,
    clientSecret,
    paymentSuccessUrl,
    dismissPaymentClientSecret,
  };
}
