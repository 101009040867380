import {
  Menu,
  MenuItem,
  Vendor,
  VendorStatus,
} from "@beinatlanda/zeppelin-shared";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase/firebase";

export const Vendors = {
  /**
   * Get vendor details subscription. It returns the subscriber to stop listening to changes.
   *
   * @param onError Query error handler callback
   */
  subscribeGetVendorsList(
    observer: (data: Vendor[] | null) => void,
    onError?: () => void
  ) {
    const q = query(
      collection(db, "vendors"),
      where("status", "==", VendorStatus.active)
    );
    const subscriber = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot) {
          observer(null);
          return;
        }
        const data = snapshot.docs.map((doc) => {
          return { id: doc?.id, ...doc?.data() } as Vendor;
        });
        observer(data);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('vendors')
    //   .where('status', '==', VendorStatus.active)
    //   .limit(15)
    //   .onSnapshot(snapshot => {
    //     if (!snapshot) {
    //       observer(null)
    //       return
    //     }
    //     const data = snapshot.docs.map(doc => {
    //       return { id: doc?.id, ...doc?.data() } as Vendor
    //     })
    //     observer(data)
    //   }, onError)
    return subscriber;
  },
  /**
   * Get vendor details subscription. It returns the subscriber to stop listening to changes.
   *
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeGetVendorDetails(
    vendorId: string,
    observer: (data: Vendor) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      doc(db, "vendors", vendorId),
      (snapshot) => {
        const data = { id: snapshot.id, ...snapshot.data() } as Vendor;
        observer(data);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('vendors')
    //   .doc(vendorId)
    //   .onSnapshot(snapshot => {
    //     const data = { id: snapshot.id, ...snapshot.data() } as Vendor
    //     observer(data)
    //   }, onError)
    return subscriber;
  },
  /**
   * Get vendor full offering subscription. It returns the subscriber to stop listening to changes.
   *
   * @param vendorId Query snapshot handler callback
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeGetVendorFullOffering(
    vendorId: string,
    observer: (data: Menu) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      doc(db, "vendors", vendorId, "offering", "fullOffering"),
      (snapshot) => {
        const data = snapshot.data() as Menu;
        observer(data);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('vendors')
    //   .doc(vendorId)
    //   .collection('offering')
    //   .doc('fullOffering')
    //   .onSnapshot(snapshot => {
    //     const data = snapshot.data() as Menu
    //     observer(data)
    //   }, onError)
    return subscriber;
  },
  /**
   * Get vendor offering item subscription. It returns the subscriber to stop listening to changes.
   *
   * @param itemId Query snapshot handler callback
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeGetVendorOfferingItem(
    itemId: string,
    vendorId: string,
    observer: (data: MenuItem) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      doc(db, "vendors", vendorId, "offering", itemId),
      (snapshot) => {
        const data = { id: snapshot.id, ...snapshot.data() } as MenuItem;
        observer(data);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('vendors')
    //   .doc(vendorId)
    //   .collection('offering')
    //   .doc(itemId)
    //   .onSnapshot(snapshot => {
    //     const data = { id: snapshot.id, ...snapshot.data() } as MenuItem
    //     observer(data)
    //   }, onError)
    return subscriber;
  },
};
