import React, { useCallback } from "react";
import { ActivityIndicator, ScrollView } from "react-native";

import { useAppTheme } from "../../../../theme";
import { Box, ButtonMain, Header, Text } from "../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useUserSubscriptionDetails } from "../../hooks/useUserSubscriptionDetails";
import { SubscriptionMetadata } from "../../types";

export const SubscriptionDetails = () => {
  const navigate = useNavigate();
  const { subscriptionId } = useParams();

  const { colors, spacing } = useAppTheme();

  const { data, isLoading, error } = useUserSubscriptionDetails(subscriptionId);
  const product = data?.plan?.product;

  console.log("data", data);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onShowIdentifier = useCallback(() => {
    navigate(`/user/subscription-proof/${subscriptionId}`);
  }, [navigate, subscriptionId]);

  if (isLoading || !product) {
    return <ActivityIndicator />;
  }

  const subscriptionMetadata = data?.metadata as SubscriptionMetadata;
  const { images, attributes, description, name } = product;

  return (
    <Box style={{ flex: 1, backgroundColor: colors.backgroundPrimary }}>
      <Header
        onBackPress={goBack}
        transparent
        showBorder={false}
        photo={images?.[0]}
      />
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: spacing.m,
          paddingTop: spacing.l,
          flexGrow: 1,
        }}
      >
        <Text variant="h1">{name}</Text>
        <Text variant="body" paddingTop="xs">
          {description}
        </Text>

        {subscriptionMetadata &&
          subscriptionMetadata.modality === "items_per_time_period" && (
            <Box
              backgroundColor="backgroundSecondary"
              padding="m"
              borderRadius="xl"
              marginTop="xl"
            >
              <Text variant="h2" textAlign="center">
                Usage
              </Text>
              <Text variant="h3" paddingTop="m">
                {`${
                  subscriptionMetadata.itemsAllowance -
                  subscriptionMetadata.itemsUsed
                } / ${subscriptionMetadata.itemsAllowance} remaining`}
              </Text>
            </Box>
          )}
      </ScrollView>
      <ButtonMain
        label="Show subscription proof"
        onPress={onShowIdentifier}
        marginHorizontal="m"
        marginVertical="s"
        backgroundColor="backgroundContrast"
      />
    </Box>
  );
};
