import React, { useCallback } from "react";
import { ActivityIndicator, ScrollView } from "react-native";

import { useAppTheme } from "../../../../theme";
import { Box, ButtonMain, Header, Text } from "../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { BulkProductPurchase } from "../../types";
import { useBulkProductPurchaseDetails } from "../../hooks/useBulkProductPurchaseDetails";

export const BulkPurchaseDetails = () => {
  const navigate = useNavigate();
  const { bulkPurchaseId } = useParams();
  console.log("bulk purchase screen");

  const { colors, spacing } = useAppTheme();

  const { data, isLoading, error } =
    useBulkProductPurchaseDetails(bulkPurchaseId);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onShowIdentifier = useCallback(() => {
    navigate(`/user/bulk-product-purchase-proof/${bulkPurchaseId}`);
  }, [bulkPurchaseId, navigate]);

  if (isLoading || !data) {
    return <ActivityIndicator />;
  }
  const { photo, name, description, itemAllowance, itemsUsed } =
    data as BulkProductPurchase;

  return (
    <Box style={{ flex: 1, backgroundColor: colors.backgroundPrimary }}>
      <Header
        onBackPress={goBack}
        transparent
        showBorder={false}
        photo={photo}
      />
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: spacing.m,
          paddingTop: spacing.l,
          flexGrow: 1,
        }}
      >
        <Text variant="h1">{name}</Text>
        <Text variant="body" paddingTop="xs">
          {description}
        </Text>

        <Box
          backgroundColor="backgroundSecondary"
          padding="m"
          borderRadius="xl"
          marginTop="xl"
        >
          <Text variant="h2" textAlign="center">
            Usage
          </Text>
          <Text variant="h3" paddingTop="m">
            {`${itemAllowance - itemsUsed} / ${itemAllowance} remaining`}
          </Text>
        </Box>
      </ScrollView>
      <ButtonMain
        label="Show purchase proof"
        onPress={onShowIdentifier}
        marginHorizontal="m"
        marginVertical="s"
        backgroundColor="backgroundContrast"
      />
    </Box>
  );
};
