import { FlatList, ListRenderItem } from "react-native";
import { Divider, TablesListItem, Text } from "../../../../components";
import { Table } from "@beinatlanda/zeppelin-shared";
import useTableScanModalScreen from "./useTableSelectionModal";
import { BottomModal } from "../../../../components";

interface TableSelectionModalProps {
  onClose: () => void;
  showModal: boolean;
  onChange: (table: Table) => void;
}

export function TableSelectionModal(props: TableSelectionModalProps) {
  const { showModal, onClose, onChange } = props;

  const { tables } = useTableScanModalScreen();

  const sortedTables = tables
    .filter((t) => t.isPublicFacing !== false)
    .sort(
      (a: Table, b: Table) =>
        a.name.length - b.name.length || a.name.localeCompare(b.name)
    );

  const renderHeader = () => {
    return (
      <>
        <Text variant="h5" paddingBottom="l" paddingHorizontal="m">
          Select your table from the list below:
        </Text>
        <Divider />
      </>
    );
  };

  const renderListItem: ListRenderItem<Table> = ({ item: table }) => {
    return <TablesListItem table={table} onPress={onChange} />;
  };

  return (
    <>
      <BottomModal open={showModal} onCloseModal={onClose}>
        <FlatList
          data={sortedTables}
          renderItem={renderListItem}
          ListHeaderComponent={renderHeader}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 50 }}
        />
      </BottomModal>
    </>
  );
}
