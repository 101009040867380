import { httpsCallable } from "firebase/functions";
import { functions } from "../services";

export const Api = {
  callApi(endpoint: string, data: any) {
    return httpsCallable(functions, "apiCallableV1")({ endpoint, ...data });
  },

  async getVendorProducts(data: any) {
    try {
      const response = await this.callApi("get-vendor-products-V2", data);
      return response.data;
    } catch (e) {
      console.log("get vendor products error", e);
    }
  },

  async getPriceDetails(data: any) {
    try {
      const response = await this.callApi("get-price-details", data);
      return response.data;
    } catch (e) {
      console.log("get price details error", e);
    }
  },

  async getUserSubscriptions() {
    try {
      const response = await this.callApi("get-user-subscriptions-V2");
      return response.data;
    } catch (e) {
      console.log("get user subscriptions error", e);
    }
  },

  async getUserSubscriptionDetails(subscriptionId: string) {
    try {
      const response = await this.callApi("get-user-subscription-details", {
        subscriptionId,
      });
      return response.data;
    } catch (e) {
      console.log("get user subscription details error", e);
    }
  },

  async getProductDetails(productId: string) {
    try {
      const response = await this.callApi("get-product-details", {
        productId,
      });
      return response.data;
    } catch (e) {
      console.log("get product details error", e);
    }
  },

  async getBulkProductDetails(productId: string, vendorId: string) {
    try {
      const response = await this.callApi("get-bulk-product-details", {
        productId,
        vendorId,
      });
      return response.data;
    } catch (e) {
      console.log("get product details error", e);
    }
  },

  async getBulkProductPurchaseDetails(id: string) {
    try {
      const response = await this.callApi("get-bulk-product-purchase-details", {
        id,
      });
      return response.data;
    } catch (e) {
      console.log("getBulkProductPurchaseDetails error", e);
    }
  },

  async getSubscriptionCheckoutSession(
    vendorId: string,
    priceId: string,
    productId?: string
  ) {
    try {
      const response = await this.callApi("get-subscription-checkout-session", {
        vendorId,
        priceId,
        productId,
      });
      return response.data;
    } catch (e) {
      console.log("get subscription checkout error", e);
    }
  },

  async createBulkPaymentIntent(paymentData: any) {
    try {
      const response = await this.callApi("create-bulk-payment-intent", {
        paymentData,
      });
      return response.data;
    } catch (e) {
      console.log("create bulk payment error", e);
    }
  },

  async postSignUpOffer() {
    try {
      const response = await this.callApi("post-sign-up-offer", {});
      return response.data;
    } catch (e) {
      console.log("post sign up offer error", e);
    }
  },
};
