import { Navigate } from "react-router-dom";
// import { Box, Text } from "../../../components";

export const Home = () => {
  return <Navigate to="/zeppelin-membership" />;
  // return (
  //   <Box
  //     flex={1}
  //     backgroundColor="backgroundSecondary"
  //     justifyContent="center"
  //     alignItems="center"
  //   >
  //     <Text variant="h1">Welcome to Zeppelin</Text>
  //     <Text variant="h4" paddingTop="m">
  //       Scan a QR code to start ordering
  //     </Text>
  //   </Box>
  // );
};
