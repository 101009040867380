import React from 'react'
import { OrderItem } from '@beinatlanda/zeppelin-shared'

import { Avatar, Box, ButtonIcon, Text } from '.'

interface OrderItemRowProps {
  item: OrderItem
  showUserPhotos?: boolean
  onCancel?: (item: OrderItem) => void
}

function OrderItemRow(props: OrderItemRowProps) {
  const { item, showUserPhotos, onCancel } = props
  const { name, count, options, totalPrice, orderedBy } = { ...item }

  const users = [orderedBy]

  return (
    <Box paddingHorizontal="m" paddingVertical="m" backgroundColor="backgroundPrimary" flexDirection="row">
      <Box minWidth={25} paddingRight="xxs">
        <Text variant="body">{`x${count}`}</Text>
      </Box>
      <Box flex={1} paddingRight="m">
        <Text variant="h6" numberOfLines={2}>
          {name}
        </Text>
        {options?.map(option => (
          <Text key={option.id} variant="h6" color="textSubdued" numberOfLines={2} paddingLeft="s">
            {option.name}
          </Text>
        ))}
      </Box>
      <Box>
        <Text variant="h5" textAlign="right">{`£${(totalPrice / 100).toFixed(2)}`}</Text>
        <Box flexDirection="row" justifyContent="flex-end">
          {!!showUserPhotos &&
            users?.splice(0, 3).map(user => <Avatar key={user.id} variant="small" photo={user.photo} />)}
        </Box>
      </Box>

      {!!onCancel && (
        <Box paddingLeft="s">
          <ButtonIcon
            variant="xSmall"
            icon="x"
            iconSize="s"
            iconColor="danger"
            backgroundColor="backgroundSecondary"
            onPress={() => {
              onCancel(item)
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(OrderItemRow)
