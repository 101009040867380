import React from "react";
import { Image } from "react-native";
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
  PositionProps,
} from "@shopify/restyle";

import { Box, ButtonIcon, Text } from ".";

import { Theme } from "../theme";

const containerRestyleFunctions = [spacing, layout, backgroundColor, border];

type HeaderProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  PositionProps<Theme> &
  LayoutProps<Theme> & {
    title?: string;
    showBorder?: boolean;
    transparent?: boolean;
    photo?: string | null;
    onBackPress?: () => void;
    onSharePress?: () => void;
  };

function Header(props: HeaderProps) {
  const {
    title,
    showBorder = true,
    transparent,
    photo,
    onBackPress,
    onSharePress,
    ...rest
  } = props;

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest });

  return (
    <>
      {!!photo && (
        <Image
          source={{ uri: photo }}
          style={{ height: 220, width: "100%" }}
          resizeMode="cover"
        />
      )}
      <Box
        {...(!transparent && { backgroundColor: "backgroundPrimary" })}
        {...(photo && { position: "absolute" })}
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom="m"
        paddingTop="s"
        paddingHorizontal="m"
        borderColor="layoutDivider"
        borderBottomWidth={showBorder ? 1 : 0}
        {...containerProps}
      >
        <Box
          flex={1}
          minHeight={40}
          justifyContent="center"
          alignItems="center"
          paddingHorizontal="xl"
        >
          {title && (
            <Text
              variant="h3"
              color="textPrimary"
              textAlign="center"
              paddingHorizontal="s"
              numberOfLines={2}
            >
              {title}
            </Text>
          )}
        </Box>
        {onBackPress && (
          <ButtonIcon
            onPress={onBackPress}
            icon="arrow-left"
            position="absolute"
            bottom={15}
            left={15}
          />
        )}
        {onSharePress && (
          <ButtonIcon
            onPress={onSharePress}
            icon="share"
            position="absolute"
            bottom={15}
            right={15}
          />
        )}
      </Box>
    </>
  );
}

export default Header;
