import { useEffect, useState } from "react";
import { Menu } from "@beinatlanda/zeppelin-shared";

import { Vendors } from "../services";

function useVendorFullOffering(vendorId: string) {
  const [vendorOffering, setVendorOffering] = useState<Menu | null>(null);

  useEffect(() => {
    if (!vendorId) return setVendorOffering(null);

    const subscriber = Vendors.subscribeGetVendorFullOffering(
      vendorId,
      (offering) => {
        setVendorOffering(offering);
      },
      () => {
        // TODO: track this in analytics
        console.log("subscribeGetVendorFullOffering fail");
      }
    );
    return () => {
      subscriber();
    };
  }, [vendorId]);

  return {
    vendorOffering,
  };
}

export default useVendorFullOffering;
