import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
  SectionList,
  SectionListData,
  SectionListRenderItem,
} from "react-native";
import {
  GuestVendor,
  MenuItem,
  MenuSection,
  OrderReferrerVendor,
  orderToAmountBreakdowns,
} from "@beinatlanda/zeppelin-shared";

import {
  BottomPanel,
  Box,
  ButtonMain,
  Divider,
  Header,
  OfferingListItem,
  SectionHeader,
  Text,
} from "../../../../components";
import {
  useAuthentication,
  useVendorDetails,
  useVendorFullOffering,
} from "../../../../hooks";
import { useLocalOrder } from "../../../../hooks/useLocalOrder";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { auth } from "../../../../services";
import { signInAnonymously } from "firebase/auth";
import { GuestVendorsDisplay } from "../../components/GuestVendorsDisplay/GuestVendorsDisplay";

type RenderSectionListItem = SectionListRenderItem<MenuItem, MenuSection>;
type RenderListSectionHeader =
  | ((info: {
      section: SectionListData<MenuItem, MenuSection>;
    }) => React.ReactElement<
      any,
      string | React.JSXElementConstructor<any>
    > | null)
  | undefined;

export function OrderVendorDetails() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const referrerVendor = searchParams.get("referrerVendor");

  const listRef = useRef<SectionList>(null);

  const { vendorId } = useParams();
  const { order, startLocalOrder, addReferrerVendorToLocalOrder } =
    useLocalOrder(vendorId);

  const { userLoggedIn, isLoaded } = useAuthentication();

  // use vendor data from order instead?
  const { vendor } = useVendorDetails(userLoggedIn && vendorId);
  const { vendorOffering } = useVendorFullOffering(userLoggedIn && vendorId);

  useEffect(() => {
    // Sign up anonymously if not authenticated
    if (isLoaded && !userLoggedIn) {
      signInAnonymously(auth);
    }
  }, [isLoaded, userLoggedIn]);

  useEffect(() => {
    // Start order
    if (vendor?.name && !order) {
      startLocalOrder(vendor);
    }
  }, [order, startLocalOrder, vendor]);

  useEffect(() => {
    // Add referrer vendor to order
    if (referrerVendor && order && !order.referrerVendorId) {
      addReferrerVendorToLocalOrder(JSON.parse(referrerVendor));
    }
  }, [addReferrerVendorToLocalOrder, order, referrerVendor, startLocalOrder]);

  const { itemsSubtotalAmount } = orderToAmountBreakdowns(
    // @ts-ignore
    order
      ? {
          ...order,
          id: "temp_id",
        }
      : { items: [] }
  );

  const itemCount = useMemo(() => {
    return (order?.items.length || 0) + (order?.userSelectedItems?.length || 0);
  }, [order?.items.length, order?.userSelectedItems?.length]);

  const { name, description, photo } = { ...vendor };

  const onItemPressHandler = useCallback(
    (itemId: string) => {
      if (!vendorId) return;

      navigate(`/table-order/${vendorId}/${itemId}`);
    },
    [navigate, vendorId]
  );

  const viewOrderHandler = useCallback(() => {
    navigate("Order");
  }, [navigate]);

  const linkToGuestVendor = (guestVendor: GuestVendor) => {
    if (!vendor || !guestVendor) return;

    // const baseUrl = process.env.REACT_APP_PLATFORM_BASE_URL;
    const baseUrl = window.location.host;
    const referrerVendorData: OrderReferrerVendor = {
      id: vendor.id,
      name: vendor.name,
      description: vendor.description,
      address: vendor.address,
      // query strings in photo url break things
      // photo: vendor.photo,
      type: vendor.type,
    };
    const linkUrl = `https://${baseUrl}/table-order/${
      guestVendor.id
    }?referrerVendor=${JSON.stringify(referrerVendorData)}`;

    window.open(linkUrl, "_blank");
  };

  const displayGuestVendors =
    !!vendor?.guestVendors?.isActive && !order?.referrerVendorId;

  const renderListHeader = () => {
    return (
      <Box>
        <Header
          // onBackPress={goBack}
          transparent
          showBorder={false}
          photo={photo}
        />
        <Box
          paddingHorizontal="m"
          paddingVertical="m"
          backgroundColor="backgroundPrimary"
        >
          <Text variant="h1">{name}</Text>
          <Text variant="body" color="textSubdued" paddingTop="xxs">
            {description}
          </Text>
          {displayGuestVendors && (
            <GuestVendorsDisplay
              guestVendorsData={vendor?.guestVendors}
              linkToVendor={linkToGuestVendor}
            />
          )}
          {!!order?.referrerVendor && (
            <Text
              variant="h5"
              textAlign="center"
              marginTop="m"
            >{`Ordering from ${order.referrerVendor?.name}`}</Text>
          )}
        </Box>
      </Box>
    );
  };

  const renderItem: RenderSectionListItem = ({ item, index, section }) => {
    const {
      id,
      name: itemName,
      description: itemDescription,
      price: itemPrice,
      discountedPrice: itemDiscountedPrice,
      image: itemImage,
      available,
    } = item;
    const {
      available: sectionAvailable,
      isPublicFacing: sectionIsPublicFacing,
    } = section;

    if (
      !available ||
      sectionIsPublicFacing === false ||
      sectionAvailable === false
    )
      return null;

    return (
      <>
        {index !== 0 && <Divider width="80%" />}
        <OfferingListItem
          key={id}
          id={id}
          name={itemName}
          description={itemDescription}
          price={itemPrice}
          discountedPrice={itemDiscountedPrice}
          photo={itemImage}
          onPress={onItemPressHandler}
        />
      </>
    );
  };

  const renderSectionHeader: RenderListSectionHeader = ({ section }) => {
    const { categoryName, data, available, isPublicFacing } = section;

    if (
      available === false ||
      isPublicFacing === false ||
      !data?.filter((i) => i.available).length
    )
      return null;

    return <SectionHeader title={categoryName} />;
  };

  if (!vendorOffering) return null;

  return (
    <Box flex={1} backgroundColor="backgroundPrimary">
      <SectionList
        ref={listRef}
        ListHeaderComponent={renderListHeader}
        sections={vendorOffering.data}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        stickySectionHeadersEnabled={false}
        bounces={false}
      />
      <Box flex={1} backgroundColor="backgroundSecondary" />
      {!!order && (
        <BottomPanel>
          <ButtonMain
            label="View order"
            price={`£${(itemsSubtotalAmount / 100).toFixed(2)}`}
            count={itemCount}
            onPress={viewOrderHandler}
          />
        </BottomPanel>
      )}
    </Box>
  );
}
