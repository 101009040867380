import React, { useCallback, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import {
  Avatar,
  BottomModal,
  BottomPanel,
  Box,
  ButtonIcon,
  ButtonMain,
  Text,
} from "../../../../components";
import { useAuthentication } from "../../../../hooks";
import { Navigate, useNavigate } from "react-router-dom";
import { auth, functions } from "../../../../services";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { httpsCallable } from "firebase/functions";
import useUser from "../../../../hooks/useUser";

const zeppelinLogo = require("../../../../assets/zeppelin_logo.png");

export function ZeppelinMembershipLanding() {
  const navigate = useNavigate();

  const { isLoaded, userLoggedIn, isAnonimous } = useAuthentication();
  const isUserAuthenticated = isLoaded && userLoggedIn && !isAnonimous;

  const [loading, setLoading] = useState(false);

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const { user } = useUser(auth.currentUser?.uid);
  const isZeppelinMember = user?.activeSubscriptions?.includes(
    "zeppelin-membership"
  );

  const [showEmailAuth, setShowEmailAuth] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const zeppelinMembershipCheckoutHandler = useCallback(async () => {
    setLoading(true);

    const response = await httpsCallable(
      functions,
      "createZeppelinMembershipCheckoutSessionV1"
    )();
    const { checkout_session_url } = response.data as any;

    if (checkout_session_url) {
      window.open(checkout_session_url, "_self");
    }

    setLoading(false);
  }, []);

  if (isZeppelinMember) return <Navigate to="/user/subscriptions" />;

  return (
    <>
      <Box flex={1} backgroundColor="backgroundPrimary">
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Box
            paddingHorizontal="m"
            paddingTop="l"
            backgroundColor="backgroundLightPrimary"
            alignItems="center"
          >
            {!currentUserProfilePhoto?.length ? (
              <ButtonIcon
                icon="user"
                backgroundColor="backgroundSecondary"
                onPress={openMenu}
                position="absolute"
                top={20}
                right={15}
              />
            ) : (
              <TouchableOpacity
                onPress={openMenu}
                style={{ position: "absolute", top: 20, right: 15 }}
              >
                <Avatar photo={currentUserProfilePhoto} variant="large" />
              </TouchableOpacity>
            )}
            <Image
              source={zeppelinLogo}
              style={{ height: 28, width: "100%" }}
              resizeMode="contain"
            />
            <Text
              variant="h1"
              fontSize={18}
              fontWeight="500"
              marginTop="l"
              paddingTop="m"
              textAlign="center"
            >
              Welcome to
            </Text>
            <Text variant="h1" fontSize={42} textAlign="center">
              Zeppelin membership
            </Text>
            <Text
              variant="body"
              fontWeight="300"
              fontSize={22}
              color="textSubdued"
              paddingTop="s"
              textAlign="center"
            >
              Experience your neighbourhood like never before.
            </Text>

            <Box
              marginTop="m"
              paddingHorizontal="m"
              paddingVertical="l"
              borderRadius="xl"
              backgroundColor="backgroundContrast"
              justifyContent="center"
              alignItems="center"
              bottom={-20}
            >
              <Text
                variant="body"
                color="textAlt"
                textDecorationLine="line-through"
              >
                only £4.99/month
              </Text>
              <Text variant="h1" color="textAlt">
                30 day free trial
              </Text>
              <Text
                variant="h6"
                fontWeight="300"
                color="textAlt"
                marginTop="m"
                textAlign="center"
              >
                We're offering a 30-day free trial for all new members. Try it
                out. Cancel any time.
              </Text>
            </Box>
          </Box>

          <Box paddingHorizontal="m" paddingVertical="xl" alignItems="center">
            <Text variant="h4" paddingTop="m">
              Save while you savor
            </Text>
            <Text
              variant="body"
              fontSize={15}
              color="textSubdued"
              textAlign="center"
              paddingTop="m"
            >
              For just £4.99 per month enjoy 10% discounts on your favourite
              restaurants and bars.
            </Text>
            <Text variant="h4" paddingTop="xl">
              Ready to take flight with Zeppelin?
            </Text>
            <Text
              variant="body"
              fontSize={15}
              color="textSubdued"
              paddingTop="m"
            >
              Getting started is easy. Click the 'Join Now' button below, fill
              out your details, and embark on a flavor-packed adventure through
              your neighbourhood's culinary map.
            </Text>
            <Text
              variant="body"
              fontSize={15}
              color="textSubdued"
              paddingTop="s"
            >
              Remember, your first month is on us. You have nothing to lose and
              a world of local flavor to gain!
            </Text>
          </Box>
        </ScrollView>

        <EmailAuthModal
          visible={showEmailAuth}
          onClose={() => setShowEmailAuth(false)}
          onPostLogIn={zeppelinMembershipCheckoutHandler}
          onPostSignUp={zeppelinMembershipCheckoutHandler}
        />

        <BottomPanel
        // content={{
        //   key: "Monthly price after first month:",
        //   value: `£4.99`,
        // }}
        >
          <ButtonMain
            label="Join now for free"
            loading={loading}
            onPress={() => {
              isUserAuthenticated
                ? zeppelinMembershipCheckoutHandler()
                : setShowEmailAuth(true);
            }}
          />
          {!!isUserAuthenticated && (
            <ButtonMain
              color="textPrimary"
              backgroundColor="backgroundSecondary"
              label="My subscriptions"
              marginTop="xxs"
              onPress={() => {
                navigate("/user/subscriptions");
              }}
            />
          )}
        </BottomPanel>
      </Box>

      {isUserAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                navigate("/user/feed");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Feed
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/subscriptions");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={closeMenu}
          onPostSignUp={closeMenu}
        />
      )}
    </>
  );
}
