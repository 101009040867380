import { Image, ScrollView, TouchableOpacity } from "react-native";
import { Check } from "react-feather";

import {
  BottomPanel,
  Box,
  ButtonMain,
  Divider,
  Header,
  OrderItemRow,
  Text,
} from "../../../../components";
import { useAppTheme } from "../../../../theme";
import { usePaymentSuccessScreen } from "./usePaymentSuccessScreen";
import { useNavigate } from "react-router-dom";

const instagramIcon = require("../../../../assets/instagram_icon.png");

export const PaymentSuccess = () => {
  const { colors } = useAppTheme();

  const navigate = useNavigate();

  const { order, downloadZeppelinHandler, newOrderHandler } =
    usePaymentSuccessScreen();

  const instagramLink = order?.vendor?.socials?.instagram;

  return (
    <>
      <Header title="Payment success" />
      <ScrollView
        style={{ backgroundColor: colors.backgroundPrimary }}
        contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
        alwaysBounceVertical={false}
      >
        <Box
          height={100}
          width={100}
          borderRadius="xxxl"
          borderWidth={2}
          borderColor="secondary"
          justifyContent="center"
          alignItems="center"
          marginTop="l"
        >
          <Check size={50} color={colors.secondary} />
        </Box>
        <Text variant="h5" color="secondary" paddingTop="m">
          Your order is on the way
        </Text>

        <TouchableOpacity onPress={() => navigate("/zeppelin-membership")}>
          <Box
            marginTop="s"
            marginHorizontal="m"
            paddingHorizontal="m"
            paddingVertical="l"
            borderRadius="xl"
            backgroundColor="backgroundContrast"
            justifyContent="center"
            alignItems="center"
            bottom={-20}
          >
            <Text
              variant="body"
              color="textAlt"
              textDecorationLine="line-through"
            >
              only £4.99/month
            </Text>
            <Text variant="h2" color="textSubdued" paddingTop="s">
              30 day free trial
            </Text>
            <Text variant="h1" color="textAlt">
              Zeppelin membership
            </Text>
            <Text
              variant="h6"
              fontWeight="300"
              color="textAlt"
              marginTop="m"
              textAlign="center"
            >
              We're offering a 30-day free trial for all new members. Try it
              out. Cancel any time.
            </Text>
            <Text
              variant="h5"
              color="primary"
              textAlign="center"
              paddingTop="m"
            >
              TRY IT NOW
            </Text>
          </Box>
        </TouchableOpacity>

        {!!order && (
          <Box width="100%" padding="m" paddingTop="xl">
            <Text variant="h5">Order details</Text>
            <Text variant="h6" color="textSubdued" paddingTop="xs">
              Items:
            </Text>
            {order.items?.map((item, index) => {
              return (
                <>
                  {index !== 0 && <Divider key={index} width="80%" />}
                  <OrderItemRow key={item.id} item={item} />
                </>
              );
            })}
            <Text variant="h5" paddingTop="m" color="textSubdued">
              Table: {order.tableName}
            </Text>
            {!!order?.referrerVendor && (
              <Text variant="h6" paddingTop="m" color="textSubdued">
                Ordered from {order?.referrerVendor?.name}
              </Text>
            )}
          </Box>
        )}
      </ScrollView>
      <BottomPanel>
        {/* <ButtonMain
          variant="outline"
          color="primary"
          label="Download Zeppelin app"
          marginBottom="xs"
          onPress={downloadZeppelinHandler}
        /> */}
        {!!instagramLink && (
          <TouchableOpacity
            onPress={() => {
              window.open(instagramLink, "blank");
            }}
          >
            <Box
              borderColor="primary"
              borderWidth={1}
              borderRadius="m"
              marginBottom="xs"
              flexDirection="row"
              height={50}
              justifyContent="center"
              alignItems="center"
            >
              <Image
                source={instagramIcon}
                style={{ height: 30, width: 30, marginRight: 10 }}
              />
              <Text variant="h4" color="primary">
                Follow us on Instagram
              </Text>
            </Box>
          </TouchableOpacity>
        )}
        <ButtonMain label="New order" onPress={newOrderHandler} />
      </BottomPanel>
    </>
  );
};
