import { useEffect, useState } from "react";
import { ZeppelinMembership } from "@beinatlanda/zeppelin-shared";
import { Subscriptions } from "../../../services";

export const useZeppelinMembership = () => {
  const [data, setData] = useState<ZeppelinMembership | null>(null);

  useEffect(() => {
    const subscriber = Subscriptions.subscribeZeppelinMembershipData(setData);

    return () => {
      subscriber();
    };
  }, []);

  return {
    data,
  };
};
