import useTables from "../../../../hooks/useTables";
import { useParams } from "react-router-dom";
import { useLocalOrder } from "../../../../hooks/useLocalOrder";

function useTableSelectionModal() {
  const { vendorId } = useParams();

  const { order } = useLocalOrder(vendorId);

  const tableVendorId = order?.referrerVendorId || vendorId;

  const { tables } = useTables(tableVendorId);

  return {
    tables,
  };
}

export default useTableSelectionModal;
