import { useCallback, useState } from "react";
import { Auth } from "../../../../services";
import { useNavigate } from "react-router-dom";

interface useEmailLogInScreenProps {
  onPostLogIn?: () => void;
}

export const useEmailLogInScreen = (props?: useEmailLogInScreenProps) => {
  const { onPostLogIn } = props;

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(async () => {
    try {
      if (!email.length || !password.length) {
        setError("All fields are required");
        return;
      }
      setError(null);

      setLoading(true);
      await Auth.signInWithEmail(email, password);

      await onPostLogIn?.();

      setLoading(false);
    } catch (e) {
      // const firebaseAuthError = e as FirebaseAuthTypes.NativeFirebaseAuthError
      setError(e.message);
      setLoading(false);
    }
  }, [email, onPostLogIn, password]);

  const onSignUp = () => {
    navigate("EmailSignUp");
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    onSubmit,
    onSignUp,
    error,
    loading,
  };
};
