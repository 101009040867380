import { useTheme } from "@shopify/restyle";
import React from "react";
import { Modal, TouchableOpacity } from "react-native";

import { Box, ButtonIcon } from ".";
import { Theme } from "../theme";

interface BottomModalProps {
  open: boolean;
  onCloseModal: () => void;
  children: React.ReactNode;
}

function BottomModal(props: BottomModalProps) {
  const { open, onCloseModal, children } = props;

  const { colors } = useTheme<Theme>();

  return (
    <Modal
      visible={open}
      transparent
      animationType="fade"
      onDismiss={onCloseModal}
      onRequestClose={onCloseModal}
    >
      <Box flex={1} justifyContent="flex-end">
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: colors.modalBackground,
          }}
          onPress={onCloseModal}
        />
        <Box
          backgroundColor="backgroundPrimary"
          borderTopLeftRadius="l"
          borderTopRightRadius="l"
          paddingTop="m"
          paddingBottom="s"
          paddingHorizontal="m"
          maxHeight="90vh"
        >
          <ButtonIcon icon="x" alignSelf="flex-end" onPress={onCloseModal} />
          {children}
        </Box>
      </Box>
    </Modal>
  );
}

export default React.memo(BottomModal);
