import { useEffect, useState } from 'react'
import { Table } from '@beinatlanda/zeppelin-shared'

import { Tables } from '../services'

const useTables = (vendorId: string) => {
  const [tables, setTables] = useState<Table[]>([])

  useEffect(() => {
    const subscriber = Tables.subscribeTables(vendorId, setTables)

    return () => {
      subscriber()
    }
  }, [vendorId])

  return { tables }
}

export default useTables
