import React from 'react'
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from '@shopify/restyle'

import { Box } from '.'

import { Theme } from '../theme'

const containerRestyleFunctions = [spacing, layout, backgroundColor, border]

type ProgressBarProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    value: number
  }

const ProgressBar = (props: ProgressBarProps) => {
  const { value, ...rest } = props

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest })

  return (
    <Box height={4} width="100%" borderRadius="m" backgroundColor="layoutDivider" {...containerProps}>
      <Box height="100%" width={`${value * 100}%`} backgroundColor="secondary" borderRadius="m" />
    </Box>
  )
}
export default ProgressBar
