import React, { useCallback, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import {
  Avatar,
  BottomModal,
  BottomPanel,
  Box,
  ButtonIcon,
  ButtonMain,
  Text,
} from "../../../../components";
import { useAuthentication } from "../../../../hooks";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { auth, functions } from "../../../../services";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { httpsCallable } from "firebase/functions";
import { useProductDetails } from "../../hooks/useProductDetails";
import { useMutation } from "@tanstack/react-query";
import { Api } from "../../../../api";

const zeppelinLogo = require("../../../../assets/zeppelin_logo.png");

export function SubscriptionLanding() {
  const navigate = useNavigate();
  const { productId, vendorId } = useParams();

  const { data, isLoading } = useProductDetails(productId);

  const { mutate, isLoading: isFetchingCheckoutSession } = useMutation({
    mutationFn: () =>
      Api.getSubscriptionCheckoutSession(
        vendorId,
        data?.default_price?.id,
        productId
      ),
    onSuccess: (checkout_session_url) => {
      console.log({ checkout_session_url });
      if (checkout_session_url) {
        window.open(checkout_session_url, "_self");
      }
    },
  });

  console.log("data", data);

  const { isLoaded, userLoggedIn, isAnonimous } = useAuthentication();
  const isUserAuthenticated = isLoaded && userLoggedIn && !isAnonimous;

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const [showEmailAuth, setShowEmailAuth] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  if (isLoading || !data) return <ActivityIndicator />;

  const { default_price, description, name, images } = data;
  const { unit_amount, active, id: priceId } = default_price;

  return (
    <>
      <Box flex={1} backgroundColor="backgroundPrimary">
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Box
            paddingHorizontal="m"
            paddingTop="l"
            backgroundColor="backgroundLightPrimary"
            alignItems="center"
            paddingBottom="l"
          >
            {!currentUserProfilePhoto?.length ? (
              <ButtonIcon
                icon="user"
                backgroundColor="backgroundSecondary"
                onPress={openMenu}
                position="absolute"
                top={20}
                right={15}
              />
            ) : (
              <TouchableOpacity
                onPress={openMenu}
                style={{ position: "absolute", top: 20, right: 15 }}
              >
                <Avatar photo={currentUserProfilePhoto} variant="large" />
              </TouchableOpacity>
            )}
            <Image
              source={zeppelinLogo}
              style={{ height: 24, width: "100%" }}
              resizeMode="contain"
            />
            <Text variant="h1" fontSize={42} textAlign="center" paddingTop="xl">
              {name}
            </Text>

            <Image
              source={{ uri: images?.[0] }}
              style={{ height: 300, width: "100%", marginTop: 32 }}
            />
            <Text
              variant="body"
              fontWeight="300"
              fontSize={22}
              color="textSubdued"
              paddingTop="l"
              textAlign="center"
            >
              {description}
            </Text>
          </Box>
        </ScrollView>

        <EmailAuthModal
          visible={showEmailAuth}
          onClose={() => setShowEmailAuth(false)}
          onPostLogIn={mutate}
          onPostSignUp={mutate}
        />

        <BottomPanel
          content={{
            key: "Monthly price:",
            value: `£${unit_amount / 100}/month`,
          }}
        >
          {!!active && !!priceId && (
            <ButtonMain
              label="Subscribe now"
              loading={isFetchingCheckoutSession}
              onPress={() => {
                isUserAuthenticated ? mutate() : setShowEmailAuth(true);
              }}
            />
          )}
          {!!isUserAuthenticated && (
            <ButtonMain
              color="textPrimary"
              backgroundColor="backgroundSecondary"
              label="My subscriptions"
              onPress={() => {
                navigate("/user/subscriptions");
              }}
              marginTop="xs"
            />
          )}
        </BottomPanel>
      </Box>

      {isUserAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                navigate("/user/feed");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Feed
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/subscriptions");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={closeMenu}
          onPostSignUp={closeMenu}
        />
      )}
    </>
  );
}
