import React from 'react'
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from '@shopify/restyle'

import { Avatar, Box, Text } from '.'

import { Theme } from '../theme'

const containerRestyleFunctions = [spacing, layout, backgroundColor, border]

type UserSectionHeaderProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    name?: string | null
    photo?: string | null
  }

const UserSectionHeader = (props: UserSectionHeaderProps) => {
  const { name, photo, ...rest } = props

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest })

  return (
    <Box
      height={70}
      width={180}
      paddingHorizontal="m"
      paddingVertical="s"
      flexDirection="row"
      alignItems="center"
      backgroundColor="backgroundPrimary"
      {...containerProps}>
      {!!photo && <Avatar photo={photo} />}
      <Text variant="h4" paddingLeft="m">
        {name}
      </Text>
    </Box>
  )
}
export default UserSectionHeader
