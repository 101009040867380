import React, { useState } from "react";
import { Modal, TouchableOpacity } from "react-native";
import { Box, ButtonIcon } from "../../../../components";
import { useAppTheme } from "../../../../theme";
import { EmailSignUp } from "../EmailSignUp/EmailSignUp";
import { EmailLogIn } from "../EmailLogIn/EmailLogIn";

interface EmailAuthModalProps {
  visible: boolean;
  onClose: () => void;
  onPostSignUp?: () => void;
  onPostLogIn?: () => void;
}

export const EmailAuthModal = ({
  visible,
  onClose,
  onPostSignUp,
  onPostLogIn,
}: EmailAuthModalProps) => {
  const { colors } = useAppTheme();

  const [showLogIn, setShowLogIn] = useState(false);

  return (
    <Modal
      visible={visible}
      transparent
      animationType="fade"
      onDismiss={onClose}
      onRequestClose={onClose}
    >
      <Box flex={1} justifyContent="flex-end">
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: colors.modalBackground,
          }}
          onPress={onClose}
        />
        <Box
          backgroundColor="backgroundPrimary"
          borderTopLeftRadius="l"
          borderTopRightRadius="l"
          paddingTop="m"
          paddingBottom="l"
          paddingHorizontal="m"
          maxHeight="90vh"
        >
          <ButtonIcon icon="x" alignSelf="flex-end" onPress={onClose} />
          {showLogIn ? (
            <EmailLogIn
              goToSignUp={() => setShowLogIn(false)}
              onPostLogIn={onPostLogIn}
            />
          ) : (
            <EmailSignUp
              goToLogin={() => setShowLogIn(true)}
              onPostSignUp={onPostSignUp}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};
