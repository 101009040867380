import React, { ReactElement } from 'react'
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from '@shopify/restyle'

import { Theme } from '../theme'
import Box from './Box'

const containerRestyleFunctions = [spacing, layout, backgroundColor, border]

type Props = SpacingProps<Theme> & BorderProps<Theme> & BackgroundColorProps<Theme> & LayoutProps<Theme>

const Divider = (props: Props): ReactElement => {
  const styleProps = useRestyle(containerRestyleFunctions, {
    height: 1,
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'layoutDivider',
    ...props,
  })

  return <Box {...styleProps} />
}

export default Divider
