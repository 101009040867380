import { useEffect, useState } from "react";

import { auth, functions } from "../services";
import { httpsCallable } from "firebase/functions";

type StripeSubscription = {
  id: string;
  status:
    | "active"
    | "canceled"
    | "incomplete"
    | "incomplete_expired"
    | "past_due"
    | "trialing"
    | "unpaid";
  description: string | null;
  trialEnd: number | null;
  daysUntilDue: number | null;
  plan: {
    active: boolean;
    aggregate_usage: null;
    amount: number;
    amount_decimal: number;
    billing_scheme: string;
    created: number;
    currency: string;
    id: string; // price id
    interval: string;
    interval_count: number;
    livemode: boolean;
    metadata: {};
    nickname: string | null;
    object: "plan";
    product: string; // product id
    tiers_mode: null;
    transform_usage: null;
    trial_period_days: null;
    usage_type: string;
  };
};

function useCustomerStripeSubscriptions() {
  const userId = auth.currentUser?.uid;

  const [subscriptions, setSubscriptions] = useState<StripeSubscription | null>(
    null
  );

  useEffect(() => {
    const fetchSubscriptionsData = async () => {
      if (!userId) return;

      const response = await httpsCallable(
        functions,
        "getCustomerStripeSubscriptionsV1"
      )();
      const { subscriptions: subscriptionsData } = response.data as any;

      setSubscriptions(subscriptionsData);
    };

    fetchSubscriptionsData();
  }, [userId]);

  return {
    subscriptions,
  };
}

export default useCustomerStripeSubscriptions;
