import {
  OrderItem,
  OrderDB,
  Vendor,
  OrderReferrerVendor,
} from "@beinatlanda/zeppelin-shared";
import { useCallback, useState } from "react";
import { Subscriptions, auth } from "../services";

export const useLocalOrder = (vendorId: string) => {
  const rehydrateLocalOrder = () => {
    const savedOrder = window.sessionStorage.getItem(vendorId);
    return savedOrder ? JSON.parse(savedOrder) : null;
  };
  const [order, setOrder] = useState<OrderDB | null>(rehydrateLocalOrder);

  const updateOrder = useCallback(
    (ord: OrderDB) => {
      window.sessionStorage.setItem(vendorId, JSON.stringify(ord));
      setOrder(ord);
    },
    [vendorId]
  );

  const startLocalOrder = useCallback(
    (vendor: Vendor) => {
      const currentUser = auth.currentUser;
      if (!currentUser || !!order) {
        return;
      }

      updateOrder({
        stage: "ordering",
        status: "open",
        createdAt: new Date(),
        updatedAt: new Date(),
        items: [],
        // @ts-ignore
        type: "table-self-order",
        tableId: "placeholder_table_id",
        tableName: "placeholder_table_name",
        activeUserIds: [currentUser.uid],
        userIds: [currentUser.uid],
        vendorId: vendorId,
        vendor,
        users: [
          {
            id: currentUser.uid,
            name: currentUser.displayName || null,
            photo: currentUser.photoURL || null,
          },
        ],
      });
    },
    [order, updateOrder, vendorId]
  );

  const addItemToLocalOrder = useCallback(
    (item: OrderItem) => {
      updateOrder({ ...order, items: [item, ...order.items] });
    },
    [order, updateOrder]
  );

  const addZeppelinMembershipDiscountToLocalOrder = useCallback(async () => {
    const zeppelinMembership = Subscriptions.getZeppelinMembershipData();

    updateOrder({
      ...order,
      discountPercentage: (await zeppelinMembership).discountPercentage || 10,
    });
  }, [order, updateOrder]);

  const cancelItemInLocalOrder = useCallback(
    (item: OrderItem) => {
      const updatedItems = order.items.filter((i) => i.id !== item.id);
      updateOrder({ ...order, items: updatedItems });
    },
    [order, updateOrder]
  );

  const addReferrerVendorToLocalOrder = useCallback(
    (vendor: OrderReferrerVendor) => {
      if (!vendor?.id) return;

      updateOrder({
        ...order,
        referrerVendor: vendor,
        referrerVendorId: vendor.id,
      });
    },
    [order, updateOrder]
  );

  return {
    order,
    startLocalOrder,
    addItemToLocalOrder,
    cancelItemInLocalOrder,
    addReferrerVendorToLocalOrder,
    addZeppelinMembershipDiscountToLocalOrder,
  };
};
