import { Check } from "react-feather";
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
  useTheme,
} from "@shopify/restyle";

import { Box } from ".";

import { Theme } from "../theme";

const containerRestyleFunctions = [spacing, layout, backgroundColor, border];

type CheckboxProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    active?: boolean;
  };

const Checkbox = (props: CheckboxProps) => {
  const { active, ...rest } = props;

  const { colors } = useTheme<Theme>();

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest });

  return (
    <Box
      height={15}
      width={15}
      borderRadius="s"
      borderWidth={2}
      borderColor={active ? "primary" : "layoutDivider"}
      backgroundColor={active ? "primary" : "backgroundPrimary"}
      justifyContent="center"
      alignItems="center"
      {...containerProps}
    >
      {active && <Check size={10} color={colors.textAlt} />}
    </Box>
  );
};
export default Checkbox;
