import React from 'react'
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from '@shopify/restyle'

import { Box } from '.'

import { Theme } from '../theme'

const containerRestyleFunctions = [spacing, layout, backgroundColor, border]

type RadioProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    active?: boolean
  }

const Radio = (props: RadioProps) => {
  const { active, ...rest } = props

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest })

  return (
    <Box
      height={15}
      width={15}
      borderRadius="xxxl"
      borderWidth={2}
      borderColor={active ? 'primary' : 'layoutDivider'}
      backgroundColor={active ? 'primary' : 'backgroundPrimary'}
      justifyContent="center"
      alignItems="center"
      {...containerProps}>
      {active && <Box height={4} width={4} backgroundColor="textAlt" borderRadius="xxxl" />}
    </Box>
  )
}
export default Radio
