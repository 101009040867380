import React from "react";

import { Box, ButtonMain, Text, TextInput } from "../../../../components";

import { useSignUpScreen } from "./EmailSignUp.hook";
import { useAppTheme } from "../../../../theme";
import { ScrollView, TouchableOpacity } from "react-native";

interface EmailSignUpProps {
  goToLogin?: () => void;
  onPostSignUp?: () => void;
}

export const EmailSignUp = ({ goToLogin, onPostSignUp }: EmailSignUpProps) => {
  const {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    loading,
    onSubmit,
    onLogIn,
  } = useSignUpScreen({ onPostSignUp });

  const { colors, spacing } = useAppTheme();

  const handleLogIn = goToLogin ? goToLogin : onLogIn;

  return (
    <Box style={{ flex: 1, backgroundColor: colors.backgroundPrimary }}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag"
        bounces={false}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          marginHorizontal: spacing.m,
        }}
      >
        <Text variant="h1" marginBottom="m">
          Sign up
        </Text>
        <TextInput
          value={name}
          onChangeText={(text) => setName(text)}
          placeholder="Name"
          autoComplete="name"
          textContentType="name"
          autoCapitalize="none"
          height={45}
          borderWidth={1}
          paddingHorizontal="s"
          marginVertical="xs"
          borderColor="layoutDivider"
          placeholderTextColor={colors.textSubdued}
        />
        <TextInput
          value={email}
          onChangeText={(text) => setEmail(text)}
          placeholder="Email"
          autoComplete="email"
          textContentType="emailAddress"
          keyboardType="email-address"
          autoCapitalize="none"
          height={45}
          borderWidth={1}
          paddingHorizontal="s"
          marginVertical="xs"
          borderColor="layoutDivider"
          placeholderTextColor={colors.textSubdued}
        />
        <TextInput
          value={password}
          onChangeText={(text) => setPassword(text)}
          placeholder="Password"
          autoComplete="password"
          textContentType="password"
          autoCapitalize="none"
          secureTextEntry
          height={45}
          borderWidth={1}
          paddingHorizontal="s"
          marginVertical="xs"
          borderColor="layoutDivider"
          placeholderTextColor={colors.textSubdued}
        />
        <TextInput
          value={confirmPassword}
          onChangeText={(text) => setConfirmPassword(text)}
          placeholder="Confirm password"
          autoComplete="password"
          textContentType="password"
          autoCapitalize="none"
          secureTextEntry
          height={45}
          borderWidth={1}
          paddingHorizontal="s"
          marginVertical="xs"
          borderColor="layoutDivider"
          placeholderTextColor={colors.textSubdued}
        />
        {!!error && (
          <Text
            variant="h6"
            color="danger"
            textAlign="center"
            marginVertical="m"
          >
            {error}
          </Text>
        )}

        <ButtonMain
          onPress={onSubmit}
          label="Submit"
          marginTop="m"
          loading={loading}
        />
        <Text variant="h6" textAlign="center" paddingTop="m">
          Already have an account?
          <TouchableOpacity
            style={{ alignSelf: "center" }}
            onPress={handleLogIn}
          >
            <Text variant="h5" color="primary" paddingLeft="xs">
              Log in
            </Text>
          </TouchableOpacity>
        </Text>
      </ScrollView>
    </Box>
  );
};
