import React, { useCallback } from "react";
import { ActivityIndicator, Image, ScrollView } from "react-native";
import { User } from "react-feather";
import { Box, Header, Text } from "../../../../components";

import { useAppTheme } from "../../../../theme";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../../services";
import QRCode from "react-qr-code";
import { useBulkProductPurchaseDetails } from "../../hooks/useBulkProductPurchaseDetails";

export const BulkProductPurchaseProofDisplay = () => {
  const navigate = useNavigate();
  const { bulkPurchaseId } = useParams();

  const { colors, spacing } = useAppTheme();

  const { data, isLoading, error } =
    useBulkProductPurchaseDetails(bulkPurchaseId);

  const currentUserName = auth.currentUser?.displayName || null;
  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const qrCodeValue = `zeppelin-bulk-product-purchase-proof/${bulkPurchaseId}`;

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box style={{ flex: 1, backgroundColor: colors.primary }}>
      <Header onBackPress={goBack} transparent showBorder={false} />
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: spacing.m,
          paddingTop: spacing.l,
          flexGrow: 1,
          alignItems: "center",
        }}
        bounces={false}
      >
        {!!currentUserProfilePhoto && (
          <Image
            source={{ uri: currentUserProfilePhoto }}
            style={{
              height: 60,
              width: 60,
              borderRadius: spacing.xl,
              alignSelf: "center",
            }}
          />
        )}
        <Text variant="h6" paddingTop="l" color="textAlt" textAlign="center">
          Name
        </Text>
        <Text variant="h2" color="textAlt" textAlign="center">
          {currentUserName}
        </Text>

        {isLoading ? (
          <ActivityIndicator color="white" />
        ) : (
          <>
            <Box
              backgroundColor="backgroundPrimary"
              borderRadius="m"
              alignSelf="center"
              marginTop="xl"
              height={260}
              width={260}
              padding="m"
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrCodeValue}
                viewBox={`0 0 256 256`}
              />
            </Box>
            <Text variant="h1" color="textAlt" marginTop="l" textAlign="center">
              {data?.plan?.product?.name}
            </Text>
          </>
        )}
        <Text
          variant="body"
          color="textAlt"
          paddingTop="s"
          textAlign="center"
          style={{ marginTop: "auto", marginBottom: 20 }}
        >
          Show this screen to a member of staff to prove your bulk product
          purchase.
        </Text>
      </ScrollView>
    </Box>
  );
};
