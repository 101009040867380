import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Table } from '@beinatlanda/zeppelin-shared'

import Divider from './Divider'
import Text from './Text'

interface TablesListItemProps {
  table: Table
  onPress: (table: Table) => void
}

const TablesListItem = (props: TablesListItemProps) => {
  const { table, onPress } = props
  const { name } = table

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          onPress(table)
        }}>
        <Text variant="h6" paddingHorizontal="m" paddingVertical="l">{`Table ${name}`}</Text>
      </TouchableOpacity>
      <Divider />
    </>
  )
}

export default React.memo(TablesListItem)
