import { doc, onSnapshot, updateDoc, deleteDoc, setDoc } from "firebase/firestore";
import { User as UserType } from "@beinatlanda/zeppelin-shared";
import { db } from "./firebase/firebase";

export const User = {
  /**
   * subscribeUser - Subscibes to user object
   *
   * @param userId user id
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeUser(
    userId: string,
    observer: (data: UserType | null) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      doc(db, "users", userId),
      (snapshot) => {
        if (!snapshot.exists()) {
          observer(null);
          return;
        }
        const data = snapshot.data();
        const user = { id: snapshot.id, ...data } as unknown as UserType;
        observer(user);
      },
      onError
    );
    return subscriber;
  },
  /**
   * Create User
   * @param userId user id
   * @param data partial User object
   */
  createUser(userId: string, data: Partial<UserType>) {
    return setDoc(doc(db, "users", userId), { ...data });
  },
  /**
   * Update User
   * @param userId user id
   * @param data partial User object
   */
  updateUser(userId: string, data: Partial<UserType>) {
    return updateDoc(doc(db, "users", userId), { ...data });
  },
  /**
   * Delete User
   * @param userId user id
   */
  deleteUser(userId: string) {
    return deleteDoc(doc(db, "users", userId));
  },
};
