import React, { Fragment, useCallback } from "react";
import { ScrollView } from "react-native";
import { useNavigate } from "react-router-dom";

import {
  BottomPanel,
  Box,
  ButtonMain,
  CountPicker,
  Divider,
  Header,
  OptionListRow,
  SectionHeader,
  Text,
} from "../../../../components";

import { useAppTheme } from "../../../../theme";

import useOfferingItemDetailsScreen from "./useOfferingItemDetailsScreen";

export function OfferingItemDetails() {
  const navigate = useNavigate();

  const {
    itemDetails,
    optionChangeHandler,
    selectedOptionValues,
    count,
    countChangeHandler,
    itemInstancePrice,
    addToOrderHandler,
    isOrderOpen,
  } = useOfferingItemDetailsScreen();

  const { colors } = useAppTheme();

  const { name, image, description, options } = { ...itemDetails };

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!itemDetails) return null;

  return (
    <>
      <ScrollView
        style={{ backgroundColor: colors.backgroundPrimary }}
        contentContainerStyle={{ flexGrow: 1 }}
        alwaysBounceVertical={false}
      >
        <Header
          photo={image}
          onBackPress={backHandler}
          transparent
          showBorder={false}
        />
        <Box padding="m" backgroundColor="backgroundPrimary">
          <Text variant="h1">{name}</Text>
          <Text variant="body" color="textSubdued">
            {description}
          </Text>
          {/* {price && <Text variant="h5" paddingTop="xs">{`£${(price / 100).toFixed(2)}`}</Text>} */}
        </Box>
        {options?.map((option) => {
          const {
            id,
            name: optionName,
            description: optionDescription,
            required,
            multipleChoice,
            values,
          } = option;
          return (
            <Fragment key={id}>
              <SectionHeader
                title={optionName}
                description={optionDescription}
                required={required}
              />
              {values.map((value, index) => {
                const {
                  id: valueId,
                  name: itemName,
                  description: itemDescription,
                  cost,
                } = value;
                const isActive = !!selectedOptionValues.find(
                  (val) => val.id === valueId
                );

                return (
                  <Fragment key={valueId}>
                    {index !== 0 && <Divider width="80%" />}
                    <OptionListRow
                      name={itemName}
                      desctiption={itemDescription}
                      price={cost}
                      active={isActive}
                      isMultipleChoice={multipleChoice}
                      onPress={() => {
                        optionChangeHandler(option, value);
                      }}
                    />
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
        <Divider />
        {isOrderOpen && (
          <CountPicker
            value={count}
            onChange={countChangeHandler}
            alignSelf="center"
            backgroundColor="backgroundPrimary"
            marginTop="xl"
          />
        )}
      </ScrollView>
      {isOrderOpen && (
        <BottomPanel>
          <ButtonMain
            label="Add to order"
            onPress={addToOrderHandler}
            count={count}
            price={`£${((itemInstancePrice * count) / 100).toFixed(2)}`}
          />
        </BottomPanel>
      )}
    </>
  );
}
