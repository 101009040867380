import { useTheme } from '@shopify/restyle'
import React, { useEffect, useRef, useState } from 'react'
import { ScrollView, TextInput, TouchableOpacity } from 'react-native'

import { Box, Text } from '.'
import { Theme } from '../theme'

const tipPercentageValues = [5, 10, 20, 25, 50]

enum TipSelectionType {
  percentage = 'percentage',
  amount = 'amount',
}

interface TipPickerProps {
  tipBase: number
  onChange: (value: number) => void
}

function TipPercentageButton(props: {
  percentage: number
  amount: number
  isSelected: boolean
  onPress: (value: { type: TipSelectionType; value: number } | null) => void
}) {
  const { percentage, amount, isSelected, onPress } = props

  const { spacing } = useTheme<Theme>()
  return (
    <TouchableOpacity
      style={{ marginRight: spacing.xs }}
      onPress={() => {
        if (isSelected) {
          onPress(null)
          return
        }
        onPress({ type: TipSelectionType.percentage, value: amount })
      }}>
      <Box
        width={120}
        backgroundColor="backgroundPrimary"
        borderWidth={2}
        borderColor={isSelected ? 'primary' : 'layoutDivider'}
        padding="m"
        borderRadius="s">
        <Text variant="h5" color="primary">{`${percentage}%`}</Text>
        <Text variant="h3" paddingTop="m">{`£${(amount / 100).toFixed(2)}`}</Text>
      </Box>
    </TouchableOpacity>
  )
}

function CutomTipInput({
  onChange,
  isSelected,
}: {
  onChange: (value: { type: TipSelectionType; value: number }) => void
  isSelected: boolean
}) {
  const [customTip, setCustomTip] = useState('0.00')

  const inputRef = useRef<TextInput>(null)

  const focusHandler = () => {
    if (!customTip.length) {
      setCustomTip((0).toFixed(2))
      return
    }

    const value = Number.parseFloat(customTip) * 100
    onChange({ type: TipSelectionType.amount, value })
  }

  return (
    <TouchableOpacity
      onPress={() => {
        inputRef.current?.focus()
      }}>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginHorizontal="m"
        paddingHorizontal="m"
        height={50}
        borderWidth={2}
        borderColor={isSelected ? 'primary' : 'layoutDivider'}
        borderRadius="s">
        <Text variant="h6">Set a custom tip value:</Text>
        <Text variant="h5" style={{ marginLeft: 'auto' }}>
          £
        </Text>
        <TextInput
          ref={inputRef}
          value={customTip}
          style={{ fontWeight: 'bold' }}
          onChangeText={text => {
            setCustomTip(text)
          }}
          onFocus={focusHandler}
          onBlur={focusHandler}
          keyboardType="decimal-pad"
          selectTextOnFocus
          returnKeyType="done"
        />
      </Box>
    </TouchableOpacity>
  )
}

function TipPicker(props: TipPickerProps) {
  const { tipBase, onChange } = props

  const { spacing } = useTheme<Theme>()

  const [tipValue, setTipValue] = useState<{ type: TipSelectionType; value: number } | null>(null)

  useEffect(() => {
    if (!tipValue) {
      onChange(0)
      return
    }
    onChange(tipValue.value)
  }, [onChange, tipValue])

  return (
    <Box width="100%" backgroundColor="backgroundPrimary" paddingVertical="m">
      <Text variant="h4" paddingHorizontal="m">
        Enjoyed our service?{' '}
        <Text variant="body" color="textSubdued">
          Add a tip:
        </Text>
      </Text>
      <ScrollView
        horizontal
        contentContainerStyle={{ paddingHorizontal: spacing.m, paddingVertical: spacing.m }}
        showsHorizontalScrollIndicator={false}>
        {tipPercentageValues.map(percentage => {
          const amount = Math.ceil((tipBase * percentage) / 100)
          const isSelected = tipValue?.type === TipSelectionType.percentage && tipValue?.value === amount

          return (
            <TipPercentageButton
              key={percentage}
              percentage={percentage}
              amount={amount}
              onPress={setTipValue}
              isSelected={isSelected}
            />
          )
        })}
      </ScrollView>
      <CutomTipInput onChange={setTipValue} isSelected={tipValue?.type === TipSelectionType.amount} />
    </Box>
  )
}

export default React.memo(TipPicker)
