import { useEffect, useState } from "react";
import { Vendor } from "@beinatlanda/zeppelin-shared";

import { Vendors } from "../services";

function useVendorDetails(vendorId?: string) {
  const [vendor, setVendor] = useState<Vendor | null>(null);

  useEffect(() => {
    if (!vendorId) return setVendor(null);

    const subscriber = Vendors.subscribeGetVendorDetails(
      vendorId,
      (vendorDetails) => {
        setVendor(vendorDetails);
      },
      () => {
        // TODO: track this error in analytics
        console.log("subscribeGetVendorDetails failed");
      }
    );
    return () => {
      subscriber();
    };
  }, [vendorId]);

  return {
    vendor,
  };
}

export default useVendorDetails;
