import { auth, db } from "./firebase/firebase";
import {
  Order,
  OrderItem,
  OrderItemStatus,
  OrderPreparationTicket,
  OrderStage,
  PaymentMetadata,
  TableOrder,
  TableOrderDB,
  Vendor,
} from "@beinatlanda/zeppelin-shared";
import { groupItemsByDestination } from "../utils/functions/groupItemsByDestination";
import { cleanUndefinedValuesFromObject } from "../utils/functions/cleanUndefinedValuesFromObject";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

const orderInitialValue = {
  stage: "ordering",
  status: "open",
  createdAt: new Date(),
  updatedAt: new Date(),
  users: [],
  activeUserIds: [],
  userIds: [],
  items: [],
};

// TODO: add support for multiple open table orders?
export const Orders = {
  /**
   * Get vendor details subscription. It returns the subscriber to stop listening to changes.
   *
   * @param orderId Order id
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeTableOrder(
    orderId: string,
    observer: (data: TableOrder | null) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      doc(db, "orders", orderId),
      (snapshot) => {
        if (!snapshot.exists()) {
          observer(null);
          return;
        }

        const order = { id: snapshot.id, ...snapshot.data() } as TableOrder;
        observer(order);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('orders')
    //   .doc(orderId)
    //   .onSnapshot(snapshot => {
    //     if (!snapshot.exists()) {
    //       observer(null)
    //       return
    //     }

    //     const order = { id: snapshot.id, ...snapshot.data() } as TableOrder
    //     observer(order)
    //   }, onError)
    return subscriber;
  },
  /**
   * Get vendor details subscription. It returns the subscriber to stop listening to changes.
   *
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeGetOpenTableOrder(
    observer: (data: TableOrder | null) => void,
    onError?: () => void
  ) {
    const userId = auth.currentUser?.uid;
    const q = query(
      collection(db, "orders"),
      where("activeUserIds", "array-contains", userId),
      where("status", "==", "open"),
      where("type", "==", "table"),
      limit(1)
    );
    const subscriber = onSnapshot(
      q,
      (snapshot) => {
        const doc = snapshot.docs[0];
        if (!doc) return observer(null);

        const order = { id: doc.id, ...doc.data() } as TableOrder;
        observer(order);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('orders')
    //   .where('activeUserIds', 'array-contains', userId)
    //   .where('status', '==', 'open')
    //   .where('type', '==', 'table')
    //   .limit(1)
    //   .onSnapshot(snapshot => {
    //     const doc = snapshot.docs[0]
    //     if (!doc) return observer(null)

    //     const order = { id: doc.id, ...doc.data() } as TableOrder
    //     observer(order)
    //   }, onError)
    return subscriber;
  },
  // TODO: add support for multiple take-away orders
  /**
   * Get vendor details subscription. It returns the subscriber to stop listening to changes.
   *
   * @param stage Stage of the order
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeGetOrderingTakeAwayOrder(
    stage: OrderStage,
    observer: (data: Order | null) => void,
    onError?: () => void
  ) {
    const userId = auth.currentUser?.uid;
    const q = query(
      collection(db, "orders"),
      where("activeUserIds", "array-contains", userId),
      where("status", "==", "open"),
      where("type", "==", "take-away"),
      where("stage", "==", stage),
      limit(1)
    );
    const subscriber = onSnapshot(
      q,
      (snapshot) => {
        const doc = snapshot.docs[0];
        if (!doc) return observer(null);

        const order = { id: doc.id, ...doc.data() } as Order;
        observer(order);
      },
      onError
    );
    // const subscriber = firestore()
    //   .collection('orders')
    //   .where('activeUserIds', 'array-contains', userId)
    //   .where('status', '==', 'open')
    //   .where('type', '==', 'take-away')
    //   .where('stage', '==', stage)
    //   .limit(1)
    //   .onSnapshot(snapshot => {
    //     const doc = snapshot.docs[0]
    //     if (!doc) return observer(null)

    //     const order = { id: doc.id, ...doc.data() } as Order
    //     observer(order)
    //   }, onError)
    return subscriber;
  },
  /**
   * Create new order given order object
   *
   * @param order order object
   */
  async createNewOrderGivenData(order: TableOrderDB) {
    return addDoc(collection(db, "orders"), {
      ...order,
      createdAt: new Date(order.createdAt),
      updatedAt: new Date(order.updatedAt),
    });
  },
  /**
   * Create new order
   *
   * @param vendor vendor detail object
   * @param tableId id of table assigned to new order
   */
  async createNewTableOrder(vendor: Vendor, tableId: string) {
    return runTransaction(db, async (transaction) => {
      const currentUser = auth.currentUser;
      if (!currentUser || !vendor) {
        return;
      }

      const { uid, displayName, photoURL } = currentUser;

      const activeOrderTableRef = doc(
        db,
        "vendors",
        vendor.id,
        "tables",
        tableId,
        "orders",
        "activeOrder"
      );

      const activeOrderCall = transaction.get(activeOrderTableRef);

      const activeOrderResponse = await activeOrderCall;

      const activeOrderExists = !!activeOrderResponse.data()?.orderId;

      if (activeOrderExists) {
        throw new Error("Order already active for this table");
      }

      // Get table info
      const tableRef = doc(db, "vendors", vendor.id, "tables", tableId);
      const tableResponse = await transaction.get(tableRef);
      const tableName = tableResponse.data()?.name;

      const newOrderRef = doc(db, "orders");
      // firestore().collection('orders').doc()
      await transaction.set(newOrderRef, {
        ...orderInitialValue,
        type: "table",
        tableId: tableId,
        tableName: tableName,
        activeUserIds: [currentUser.uid],
        userIds: [currentUser.uid],
        vendorId: vendor.id,
        vendor,
        users: [{ id: uid, name: displayName, photo: photoURL }],
      } as TableOrderDB);

      await transaction.set(activeOrderTableRef, { orderId: newOrderRef.id });

      return { id: newOrderRef.id };
    });
  },
  /**
   * Create new order
   *
   * @param vendorId id of the restaurant
   * @param tableId id of table assigned to new order
   * @param tableName name of table assigned to new order
   */
  // TODO: add vendor id to orders
  createNewTakeAwayOrder() {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const { uid, displayName, photoURL } = currentUser;

    return setDoc(doc(db, "orders"), {
      ...orderInitialValue,
      type: "take-away",
      activeUserIds: [uid],
      userIds: [currentUser.uid],
      users: [{ id: uid, name: displayName, photo: photoURL }],
      takeAwayUser: {
        id: uid,
        name: displayName,
        photo: photoURL,
      },
    });
  },
  /**
   * Join existing table order
   * @param orderId order id
   */
  // TODO: Use transaction to check that order is still open
  joinTableOrder(orderId: string) {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    const { uid, displayName, photoURL } = currentUser;

    return updateDoc(doc(db, "orders", orderId), {
      activeUserIds: arrayUnion(uid),
      userIds: arrayUnion(uid),
      users: arrayUnion({ id: uid, name: displayName, photo: photoURL }),
    });
  },
  /**
   * Add new item to order
   *
   * @param orderId order id
   * @param orderItem order item object
   */
  addItemToOrder(orderId: string, item: OrderItem) {
    return updateDoc(doc(db, "orders", orderId), {
      items: arrayUnion(item),
      updatedAt: serverTimestamp(),
    });
  },
  /**
   * Add new user selected item to order
   *
   * @param orderId order id
   * @param orderItem order item object
   */
  addUserSelectedItemToOrder(orderId: string, item: OrderItem) {
    return updateDoc(doc(db, "orders", orderId), {
      userSelectedItems: arrayUnion(item),
      updatedAt: serverTimestamp(),
    });
  },

  /**
   *
   * createDestinationTicketsFromStagedTableOrder - Creates destination tickets from order
   *
   * @param orderId Order id
   *
   */
  createDestinationTicketsFromSelectedOrder(orderId: string) {
    return runTransaction(db, async (transaction) => {
      const orderRef = doc(db, "orders", orderId);

      const orderResponse = await transaction.get(orderRef);

      if (!orderResponse || !orderResponse.exists()) {
        throw new Error("Missing order document");
      }

      const order = {
        id: orderResponse.id,
        ...orderResponse.data(),
      } as TableOrder;

      const { id, createdAt, vendor, tableId, tableName, userSelectedItems } =
        order;
      if (!vendor || !userSelectedItems?.length) return;

      const stagedItemsByDestination =
        groupItemsByDestination(userSelectedItems);

      let submittedItems: OrderItem[] = [];

      await Object.entries(stagedItemsByDestination).forEach(async (entry) => {
        const [destinationId, destinationSelectedItems] = entry;
        // @ts-ignore
        const destinationTicket: OrderPreparationTicket = {
          createdAt: new Date(),
          status: "open",
          destinationId,
          destination: destinationSelectedItems[0]?.destination,
          orderId: id,
          tableId,
          tableName,
          orderCreatedAt: createdAt,
          items: destinationSelectedItems,
        };

        const newTicketRef = doc(
          db,
          "vendors",
          vendor.id,
          "orderDestinations",
          destinationId,
          "tickets"
        );

        await transaction.set(
          newTicketRef,
          cleanUndefinedValuesFromObject(destinationTicket)
        );
        const newTicketId = newTicketRef.id;

        const updatedItems = destinationSelectedItems.map((item) => ({
          ...item,
          status: OrderItemStatus.ordered,
          destinationTicketId: newTicketId,
        }));
        submittedItems = [...submittedItems, ...updatedItems];
      });

      transaction.update(orderRef, {
        items: [...(order.items || []), ...submittedItems],
        userSelectedItems: [],
        updatedAt: serverTimestamp(),
      });
    });
  },
  /**
   * Update Order
   *
   * @param orderId order id
   * @param data partial Order object
   */
  // TODO: use transaction
  updateOrder(orderId: string, data: Partial<Order>) {
    return updateDoc(doc(db, "orders", orderId), {
      ...data,
      updatedAt: serverTimestamp(),
    });
  },
  /**
   * Add payment to order payments array
   *
   * @param orderId order id
   * @param data payment metadata object
   */
  addOrderPayment(orderId: string, payment: PaymentMetadata) {
    return updateDoc(doc(db, "orders", orderId), {
      updatedAt: serverTimestamp(),
      payments: arrayUnion(payment),
    });
  },
  // TODO: add cancelled by data to item
  /**
   * Cancel order item
   *
   * @param orderId order id
   * @param item item to be cancelled
   */
  cancelOrderItem(orderId: string, item: OrderItem) {
    return runTransaction(db, async (transaction) => {
      const orderRef = doc(db, "orders", orderId);

      const orderResponse = await transaction.get(orderRef);
      if (!orderResponse || !orderResponse.exists()) {
        throw new Error("Missing order document");
      }
      const orderData = orderResponse.data();
      const updatedOrder = {
        userSelectedItems: orderData?.userSelectedItems?.filter(
          // @ts-ignore
          (i) => i.id !== item.id
        ),
        updatedAt: serverTimestamp(),
      };

      await transaction.update(orderRef, updatedOrder);
    });
  },
  /**
   * Get table order
   *
   * @param vendorId vendor id
   * @param tableId table id
   */
  // TODO: add vendor id to order objects
  getTableOrder(vendorId: string, tableId: string) {
    const q = query(
      collection(db, "orders"),
      where("tableId", "==", tableId),
      where("status", "==", "open")
    );
    return getDocs(q);
    // return firestore()
    //   .collection("orders")
    //   .where("tableId", "==", tableId)
    //   .where("status", "==", "open")
    //   .get();
  },
  /**
   * Get take-away order
   *
   * @param vendorId vendor id
   */
  // TODO: add vendor id to order objects
  getTakeAwayOrder() {
    const currentUser = auth.currentUser;
    if (!currentUser) return null;

    const { uid: userId } = currentUser;

    const q = query(
      collection(db, "orders"),
      where("type", "==", "take-away"),
      where("status", "==", "open"),
      where("activeUserIds", "array-contains", userId)
    );
    return getDocs(q);

    //   return firestore()
    //     .collection("orders")
    //     .where("type", "==", "take-away")
    //     .where("status", "==", "open")
    //     .where("activeUserIds", "array-contains", userId)
    //     .get();
  },
  /**
   * Get tables info subscription. It returns the subscriber to stop listening to changes.
   *
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeClosedOrders(
    userId: string,
    observer: (data: Order[]) => void,
    onError?: () => void
  ) {
    const q = query(
      collection(db, "orders"),
      where("status", "==", "closed"),
      where("userIds", "array-contains", userId),
      orderBy("createdAt", "desc"),
      limit(20)
    );
    const subscriber = onSnapshot(
      q,
      (snapshot) => {
        if (!snapshot || snapshot.empty) return;

        const data = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as Order;
        });
        observer(data);
      },
      onError
    );

    // const subscriber = firestore()
    //   .collection("orders")
    //   .where("status", "==", "closed")
    //   .where("userIds", "array-contains", userId)
    //   .orderBy("createdAt", "desc")
    //   .limit(20)
    //   .onSnapshot((snapshot) => {
    //     if (!snapshot || snapshot.empty) return;

    //     const data = snapshot.docs.map((doc) => {
    //       return { id: doc.id, ...doc.data() } as Order;
    //     });
    //     observer(data);
    //   }, onError);
    return subscriber;
  },
  /**
   * Get order details
   *
   * @param orderId orderId
   */
  async getOrderDetails(orderId: string) {
    const response = await getDoc(doc(db, "orders, orderId"));
    // const response = await firestore().collection("orders").doc(orderId).get();
    if (!response?.exists()) return;
    const order = { id: response.id, ...response.data() } as Order;

    return order;
  },
};
