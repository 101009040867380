import { ThemeProvider } from "@shopify/restyle";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Box from "./components/Box";
import { Navigator } from "./navigation/Navigator";
import theme from "./theme";
import { loadStripe } from "@stripe/stripe-js";

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

export const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Box
          height={window.innerHeight}
          width="100vw"
          maxWidth={500}
          style={{ marginHorizontal: "auto" }}
        >
          <Navigator />
        </Box>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
