import { useEffect, useState } from 'react'
import { TableOrder } from '@beinatlanda/zeppelin-shared'

import { Orders } from '../services'

function useTableOrder(orderId: string) {
  const [order, setOrder] = useState<TableOrder | null>(null)

  useEffect(() => {
    const subscriber = Orders.subscribeTableOrder(orderId, tableOrder => {
      setOrder(tableOrder)
    })
    return () => {
      subscriber()
    }
  }, [orderId])

  return { order }
}

export default useTableOrder
