import React, { useCallback } from "react";
import { User } from "react-feather";

import { Box, Header, Text } from "../../../components";
import useUser from "../../../hooks/useUser";
import { Alert, Image, ScrollView, TouchableOpacity } from "react-native";
import { useAppTheme } from "../../../theme";
import { Auth, auth } from "../../../services";
import { useNavigate } from "react-router-dom";

export const Account = () => {
  const navigate = useNavigate();
  const { colors } = useAppTheme();

  const currentUserId = auth.currentUser?.uid;

  const { user } = useUser(currentUserId);

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onEditPhoto = () => {
    navigate("/user/edit-profile-photo");
  };

  const logOut = useCallback(async () => {
    await Auth.logOut();
    navigate("/zeppelin-membership");
  }, []);

  const deleteUserAccount = useCallback(async () => {
    Alert.alert(
      "Delete account",
      "Are you sure you want to delete your account? This action is not reversible",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Yes, delete",
          style: "destructive",
          onPress: async () => {
            await Auth.deleteAccount();
          },
        },
      ]
    );
  }, []);

  const { fullName, displayName, email, profileImage } = { ...user };

  return (
    <Box flex={1} backgroundColor="backgroundPrimary">
      <Header title="Account" onBackPress={backHandler} />

      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 15,
        }}
        alwaysBounceVertical={false}
      >
        <TouchableOpacity
          style={{ width: 150, alignSelf: "center", marginTop: 40 }}
          // onPress={onEditPhoto}
        >
          <Box
            height={150}
            width={150}
            borderRadius="xxxl"
            borderWidth={2}
            borderColor="layoutDivider"
            justifyContent="center"
            alignItems="center"
          >
            {profileImage ? (
              <Image
                source={{ uri: profileImage }}
                style={{ height: 150, width: 150, borderRadius: 150 }}
                resizeMode="cover"
              />
            ) : (
              <User size={35} color={colors.layoutDivider} />
            )}
          </Box>
        </TouchableOpacity>

        {/* <TouchableOpacity style={{ marginTop: 15 }} onPress={onEditPhoto}>
          <Text variant="h5" color="primary" textAlign="center">
            Press to edit
          </Text>
        </TouchableOpacity> */}

        <Box paddingHorizontal="l" paddingVertical="l">
          <Text variant="h6" paddingTop="m">
            First name:
          </Text>
          <Text variant="h4">{displayName}</Text>
          <Text variant="h6" paddingTop="m">
            Full name:
          </Text>
          <Text variant="h4">{fullName}</Text>
          <Text variant="h6" paddingTop="m">
            Email:
          </Text>
          <Text variant="h4">{email}</Text>
          {/* <TouchableOpacity
            style={{ marginTop: 15 }}
            onPress={() => navigate("/user/edit-profile")}
          >
            <Text variant="h4" color="primary" textAlign="center">
              Edit
            </Text>
          </TouchableOpacity> */}
        </Box>

        <Box paddingHorizontal="m" paddingTop="l" style={{ marginTop: "auto" }}>
          <TouchableOpacity onPress={logOut}>
            <Text
              variant="h3"
              paddingVertical="s"
              fontWeight="500"
              textAlign="center"
            >
              Log out
            </Text>
          </TouchableOpacity>
          {/* <TouchableOpacity onPress={deleteUserAccount}>
            <Text variant="body" textAlign="center" color="danger">
              Delete account
            </Text>
          </TouchableOpacity> */}
        </Box>
      </ScrollView>
    </Box>
  );
};
