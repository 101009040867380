import { Auth, User, auth } from "../../../services";
import { UserType } from "@beinatlanda/zeppelin-shared";

export const postSignUp = async (name: string | null, email: string | null) => {
  try {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const displayName = name?.split(" ")[0] || "Zeppelin user";

    User.createUser(userId, {
      // createdAt: new Date(),
      displayName,
      fullName: name || "Zeppelin user",
      email,
      type: UserType.customer,
    });
    Auth.updateUserProfile({ displayName });
  } catch (error) {
    console.log("post log in error:", error);
  }
};
