import React, { useCallback, useMemo } from "react";
import { SectionList } from "react-native";

import {
  BottomPanel,
  ButtonMain,
  Divider,
  Header,
  OrderItemRow,
  SectionHeader,
  Text,
  // UserSectionHeader,
} from "../../../../components";
import useOrderScreen from "./useOrderScreen";
import { useAppTheme } from "../../../../theme";
import { useNavigate, useParams } from "react-router-dom";

export function Order() {
  const { vendorId } = useParams();
  const navigate = useNavigate();

  const { sections, itemCount, orderTotal, cancelItem } = useOrderScreen();

  const { colors } = useAppTheme();

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const renderListHeader = () => {
    if (!sections?.length) return null;
    return <SectionHeader title="Order items" />;
  };

  const mainButtonAction = useMemo(() => {
    return {
      label: "Go to checkout",
      price: `£${(orderTotal / 100).toFixed(2)}`,
      count: itemCount,
      onPress: () => navigate(`/table-order/${vendorId}/Checkout`),
    };
  }, [itemCount, navigate, orderTotal, vendorId]);

  // Todo: refactor renderItem and renderSectionHeader
  return (
    <>
      <Header title="Order" onBackPress={backHandler} />
      {!!sections && (
        <SectionList
          ListHeaderComponent={renderListHeader}
          sections={sections}
          renderItem={({ item, index }) => {
            return (
              <>
                {index !== 0 && <Divider width="80%" />}
                <OrderItemRow key={item.id} item={item} onCancel={cancelItem} />
              </>
            );
          }}
          renderSectionHeader={({ section }) => {
            // const { id, name, photo } = section;
            return (
              <>
                <Divider />
                {/* <UserSectionHeader key={id} name={name} photo={photo} /> */}
              </>
            );
          }}
          bounces={false}
          style={{ flex: 1, backgroundColor: colors.backgroundPrimary }}
          contentContainerStyle={{ flexGrow: 1 }}
          ListEmptyComponent={
            <Text
              variant="h6"
              paddingTop="xl"
              color="textSubdued"
              style={{ marginHorizontal: "auto" }}
            >
              Your order is empty
            </Text>
          }
        />
      )}
      <BottomPanel>
        <ButtonMain
          label={mainButtonAction.label}
          price={mainButtonAction.price}
          count={mainButtonAction.count}
          onPress={mainButtonAction.onPress}
        />
      </BottomPanel>
    </>
  );
}
