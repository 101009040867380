import React, { ReactElement } from "react";
import {
  useRestyle,
  createRestyleComponent,
  createVariant,
  VariantProps,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
  useTheme,
} from "@shopify/restyle";
import { TouchableOpacity } from "react-native";

import theme, { Theme } from "../theme";

import { Box, Text } from ".";
import { ActivityIndicator } from "react-native";

const containerRestyleFunctions = [spacing, layout, backgroundColor, border];

type Props = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    onPress?: () => void;
    label: string;
    testID?: string;
    color?: keyof typeof theme.colors;
    count?: number;
    price?: string;
    loading?: boolean;
    labelTextVariant?: "h4" | "h5" | "h6";
  };

const Component = (props: Props): ReactElement => {
  const {
    onPress,
    label,
    testID,
    color = "textAlt",
    price,
    count,
    loading,
    labelTextVariant = "h4",
    ...rest
  } = props;

  const { colors } = useTheme<Theme>();

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest });

  if (loading)
    return (
      <Box {...containerProps}>
        <ActivityIndicator color={colors[color]} />
      </Box>
    );

  return (
    <TouchableOpacity onPress={onPress} testID={testID} {...containerProps}>
      <Text
        variant={labelTextVariant}
        color={color}
        paddingHorizontal="xl"
        textAlign="center"
      >
        {label}
      </Text>
      {!!price && (
        <Box
          position="absolute"
          top={0}
          right={0}
          height="100%"
          paddingRight="s"
          justifyContent="center"
        >
          <Text variant="h5" color={color} fontWeight="600">
            {price}
          </Text>
        </Box>
      )}
      {!!count && (
        <Box
          position="absolute"
          top={0}
          left={0}
          height="100%"
          paddingLeft="s"
          justifyContent="center"
        >
          <Box
            paddingVertical="xxs"
            paddingHorizontal="xs"
            borderRadius="xs"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
          >
            <Text variant="h5" color={color} fontWeight="600">
              {count}
            </Text>
          </Box>
        </Box>
      )}
    </TouchableOpacity>
  );
};

const ButtonMain = createRestyleComponent<
  VariantProps<Theme, "buttonMainVariants"> & Props,
  Theme
>(
  [
    ...containerRestyleFunctions,
    createVariant({ themeKey: "buttonMainVariants" }),
  ],
  Component
);

export type ButtonMainProps = VariantProps<Theme, "buttonMainVariants"> & Props;

export default ButtonMain;
