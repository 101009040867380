import { useQuery } from "@tanstack/react-query";
import { Api } from "../../../api";

export const useBulkProductPurchaseDetails = (id: string) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["bulk-product-purchase-details", id],
    queryFn: () => Api.getBulkProductPurchaseDetails(id),
  });

  return { data, isLoading, error };
};
