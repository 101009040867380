import React, { useCallback, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import {
  Avatar,
  BottomModal,
  BottomPanel,
  Box,
  ButtonIcon,
  ButtonMain,
  Text,
} from "../../../../components";
import { useAuthentication } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../services";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { useMutation } from "@tanstack/react-query";
import { Api } from "../../../../api";

const zeppelinLogo = require("../../../../assets/zeppelin_logo.png");
const AppStoreButton = require("../../../../assets/download_on_the_app_store.jpeg");
const PlayStoreButton = require("../../../../assets/get_it_on_play_store.jpeg");

export function DownloadApp() {
  const navigate = useNavigate();

  const { isLoaded, userLoggedIn, isAnonimous } = useAuthentication();
  const isUserAuthenticated = isLoaded && userLoggedIn && !isAnonimous;

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const [showEmailAuth, setShowEmailAuth] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const postLogInHandler = useCallback(() => {
    navigate("/user/subscriptions");
  }, [navigate]);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => Api.postSignUpOffer(),
    onSuccess: () => {
      navigate("/download-app");
    },
  });

  return (
    <>
      <Box flex={1} backgroundColor="backgroundPrimary">
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Box
            flex={1}
            paddingHorizontal="m"
            paddingTop="l"
            backgroundColor="backgroundLightPrimary"
            alignItems="center"
          >
            {!currentUserProfilePhoto?.length ? (
              <ButtonIcon
                icon="user"
                backgroundColor="backgroundSecondary"
                onPress={openMenu}
                position="absolute"
                top={20}
                right={15}
              />
            ) : (
              <TouchableOpacity
                onPress={openMenu}
                style={{ position: "absolute", top: 20, right: 15 }}
              >
                <Avatar photo={currentUserProfilePhoto} variant="large" />
              </TouchableOpacity>
            )}
            <Image
              source={zeppelinLogo}
              style={{ height: 28, width: "100%" }}
              resizeMode="contain"
            />
            <Box paddingBottom="l">
              <Text
                variant="h1"
                paddingTop="xl"
                marginTop="xl"
                textAlign="center"
              >
                Download our app now!
              </Text>
              <Text variant="body" paddingVertical="m">
                Download our mobile app to always have quick access to your
                Zeppelin wallet, receive notifications and enjoy a better
                overall experience.
              </Text>
              <TouchableOpacity
                style={{ paddingTop: 100 }}
                onPress={() => {
                  window.open(
                    "https://apps.apple.com/gb/app/zeppelin/id1532928443",
                    "_blank"
                  );
                }}
              >
                <Image
                  source={AppStoreButton}
                  style={{ height: 60 }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{ paddingTop: 20 }}
                onPress={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.meetzeppelin.zeppelin_customer",
                    "_blank"
                  );
                }}
              >
                <Image
                  source={PlayStoreButton}
                  style={{ height: 59 }}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </Box>
          </Box>
        </ScrollView>
      </Box>

      {isUserAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                navigate("/user/feed");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Feed
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/subscriptions");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={postLogInHandler}
          onPostSignUp={mutate}
        />
      )}
    </>
  );
}
