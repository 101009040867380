import { Table } from "@beinatlanda/zeppelin-shared";
import { useCallback, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Box, Text } from "../../../../components";
import { TableSelectionModal } from "../TableSelectionModal/TableSelectionModal";

interface TablePickerProps {
  value: Table | null;
  onChange: (table: Table) => void;
  placeholder: string;
}

export const TablePicker = (props: TablePickerProps) => {
  const { value, onChange, placeholder } = props;

  const [showModal, setShowModal] = useState(false);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onTablePressHandler = useCallback(
    (t: Table) => {
      onChange(t);
      onCloseModal();
    },
    [onCloseModal, onChange]
  );

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          setShowModal(true);
        }}
      >
        <Box
          margin="m"
          padding="m"
          borderRadius="m"
          borderColor="layoutDivider"
          borderWidth={1}
          backgroundColor="backgroundSecondary"
        >
          {value ? (
            <Text variant="h6">{value.name}</Text>
          ) : (
            <Text variant="body" color="textSubdued">
              {placeholder}
            </Text>
          )}
        </Box>
      </TouchableOpacity>
      <TableSelectionModal
        showModal={showModal}
        onClose={onCloseModal}
        onChange={onTablePressHandler}
      />
    </>
  );
};
