import { Api } from "../../../api";
import { useQuery } from "@tanstack/react-query";

export const useUserSubscriptions = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["user-subscriptions-V2"],
    queryFn: () => Api.getUserSubscriptions(),
  });

  return { data, isLoading, error };
};
