import React from "react";

import { Box, ButtonMain, Text, TextInput } from "../../../../components";

import { useEmailLogInScreen } from "./EmailLogIn.hook";
import { useAppTheme } from "../../../../theme";
import { ScrollView, TouchableOpacity } from "react-native";

interface EmailLogInProps {
  goToSignUp?: () => void;
  onPostLogIn?: () => void;
}

export const EmailLogIn = ({ goToSignUp, onPostLogIn }: EmailLogInProps) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    error,
    loading,
    onSubmit,
    onSignUp,
  } = useEmailLogInScreen({ onPostLogIn });

  const { colors, spacing } = useAppTheme();

  const handleSignUpClick = goToSignUp ? goToSignUp : onSignUp;

  return (
    <Box style={{ flex: 1, backgroundColor: colors.backgroundPrimary }}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        keyboardDismissMode="on-drag"
        bounces={false}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "center",
          marginHorizontal: spacing.m,
        }}
      >
        <Text variant="h1" marginBottom="m">
          Log in
        </Text>
        <TextInput
          value={email}
          onChangeText={(text) => setEmail(text)}
          placeholder="Email"
          keyboardType="email-address"
          autoCapitalize="none"
          height={45}
          borderWidth={1}
          paddingHorizontal="s"
          marginVertical="xs"
          borderColor="layoutDivider"
          placeholderTextColor={colors.textSubdued}
        />
        <TextInput
          value={password}
          onChangeText={(text) => setPassword(text)}
          placeholder="Password"
          autoCapitalize="none"
          secureTextEntry
          height={45}
          borderWidth={1}
          paddingHorizontal="s"
          marginVertical="xs"
          borderColor="layoutDivider"
          placeholderTextColor={colors.textSubdued}
        />
        {!!error && (
          <Text
            variant="h6"
            color="danger"
            textAlign="center"
            marginVertical="m"
          >
            {error}
          </Text>
        )}

        <ButtonMain
          onPress={onSubmit}
          label="Submit"
          marginTop="m"
          loading={loading}
        />
        <Text variant="h6" textAlign="center" paddingTop="m">
          Don't have an account?
          <TouchableOpacity
            style={{ alignSelf: "center" }}
            onPress={handleSignUpClick}
          >
            <Text variant="h5" color="primary" paddingLeft="xs">
              Sign Up
            </Text>
          </TouchableOpacity>
        </Text>
      </ScrollView>
    </Box>
  );
};
