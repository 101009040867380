import { Table, ZeppelinMembership } from "@beinatlanda/zeppelin-shared";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "./firebase/firebase";

export const Subscriptions = {
  /**
   * Get Zeppelin membership data subscription. It returns the subscriber to stop listening to changes.
   *
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeZeppelinMembershipData(
    observer: (data: ZeppelinMembership) => void,
    onError?: () => void
  ) {
    const subscriber = onSnapshot(
      doc(db, "subscriptions", "zeppelin-membership"),
      (snapshot) => {
        const data = {
          id: snapshot.id,
          ...snapshot.data(),
        } as ZeppelinMembership;
        observer(data);
      },
      onError
    );
    return subscriber;
  },
  /**
   * Get Zeppelin membership data.
   */
  getZeppelinMembershipData: async () => {
    const res = await getDoc(doc(db, "subscriptions", "zeppelin-membership"));
    return res.exists
      ? ({ id: res.id, ...res.data() } as ZeppelinMembership)
      : null;
  },
};
