import React, { useCallback, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";

import {
  Avatar,
  BottomModal,
  BottomPanel,
  Box,
  ButtonIcon,
  ButtonMain,
  Text,
} from "../../../../components";

import { useAppTheme } from "../../../../theme";
import { auth } from "../../../../services";
import { useNavigate, useParams } from "react-router-dom";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { useAuthentication, useVendorDetails } from "../../../../hooks";
import { VendorProductsList } from "../../components/VendorProductsList";

export const VendorSubscriptionOffering = () => {
  const navigate = useNavigate();

  const { userLoggedIn, isAnonimous } = useAuthentication();
  const isAuthenticated = userLoggedIn && !isAnonimous;

  const { vendorId } = useParams();

  const { vendor } = useVendorDetails(vendorId);

  const { spacing } = useAppTheme();

  const [showMenu, setShowMenu] = useState(false);

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  return (
    <>
      <Box flex={1} paddingTop="m" backgroundColor="backgroundPrimary">
        <Box
          flexDirection="row"
          alignItems="center"
          paddingHorizontal="m"
          paddingTop="xs"
          justifyContent="flex-end"
        >
          {!currentUserProfilePhoto?.length ? (
            <ButtonIcon
              icon="user"
              backgroundColor="backgroundSecondary"
              onPress={openMenu}
            />
          ) : (
            <TouchableOpacity onPress={openMenu}>
              <Avatar photo={currentUserProfilePhoto} variant="large" />
            </TouchableOpacity>
          )}
        </Box>
        {!!vendor?.photo && (
          <Image
            source={{ uri: vendor?.photo }}
            style={{ height: 200, width: "100%", marginTop: 10 }}
          />
        )}
        <Text variant="h1" paddingTop="m" paddingHorizontal="m">
          {vendor?.name}
        </Text>
        <Text variant="body" paddingHorizontal="m" paddingBottom="m">
          {vendor?.description}
        </Text>
        <ScrollView
          contentContainerStyle={{
            paddingHorizontal: spacing.m,
            paddingTop: spacing.l,
          }}
        >
          <VendorProductsList vendorId={vendorId} />
          <BottomPanel>
            {!!isAuthenticated && (
              <ButtonMain
                color="textPrimary"
                backgroundColor="backgroundSecondary"
                label="My subscriptions"
                onPress={() => {
                  navigate("/user/subscriptions");
                }}
              />
            )}
          </BottomPanel>
        </ScrollView>
      </Box>

      {isAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                console.log(
                  process.env.REACT_APP_STRIPE_BILLING_CUSTOMER_PORTAL_LINK
                );
                window.open(
                  process.env.REACT_APP_STRIPE_BILLING_CUSTOMER_PORTAL_LINK
                );
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Manage subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/subscriptions");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={closeMenu}
          onPostSignUp={closeMenu}
        />
      )}
    </>
  );
};
