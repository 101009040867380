import React from "react";

import { Box, Text } from ".";

interface BottomPanelProps {
  children?: React.ReactNode;
  content?: {
    key: string;
    value: string;
  };
}

function BottomPanel(props: BottomPanelProps) {
  const { children, content } = props;

  return (
    <Box
      paddingHorizontal="m"
      backgroundColor="backgroundPrimary"
      borderTopWidth={1}
      borderColor="layoutDivider"
      paddingTop="s"
      paddingBottom="m"
    >
      {!!content && (
        <Box
          flexDirection="row"
          justifyContent="space-between"
          paddingBottom="s"
        >
          <Text variant="h6">{content.key}</Text>
          <Text variant="h5">{content.value}</Text>
        </Box>
      )}
      {children}
    </Box>
  );
}

export default BottomPanel;
