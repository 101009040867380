import React from 'react'
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from '@shopify/restyle'

import { Box, ButtonIcon, Text } from '.'

import { Theme } from '../theme'

const containerRestyleFunctions = [spacing, layout, backgroundColor, border]

type CountPickerProps = SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    value: number
    onChange: (value: number) => void
  }

const CountPicker = (props: CountPickerProps) => {
  const { value, onChange, ...rest } = props

  const containerProps = useRestyle(containerRestyleFunctions, { ...rest })

  return (
    <Box
      height={70}
      width={180}
      padding="m"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      {...containerProps}>
      <ButtonIcon
        icon="minus"
        backgroundColor="backgroundSecondary"
        onPress={() => {
          !!value && onChange(value - 1)
        }}
      />
      <Text variant="h4">{value}</Text>
      <ButtonIcon
        icon="plus"
        backgroundColor="backgroundSecondary"
        onPress={() => {
          onChange(value + 1)
        }}
      />
    </Box>
  )
}
export default CountPicker
