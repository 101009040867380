import { FeedItem } from "@beinatlanda/zeppelin-shared";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebase/firebase";

export const Feed = {
  /**
   * Get feed items subscription. It returns the subscriber to stop listening to changes.
   *
   * @param observer Query snapshot handler callback
   * @param onError Query error handler callback
   */
  subscribeFeed(observer: (data: FeedItem[]) => void, onError?: () => void) {
    const subscriber = onSnapshot(
      query(collection(db, "feed"), orderBy("createdAt", "desc")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() } as FeedItem;
        });
        observer(data);
      },
      onError
    );
    return subscriber;
  },
};
