import { useQuery } from "@tanstack/react-query";
import { Api } from "../../../api";

export const useBulkProductDetails = (productId: string, vendorId: string) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["bulk-product-details", productId],
    queryFn: () => Api.getBulkProductDetails(productId, vendorId),
  });

  return { data, isLoading, error };
};
