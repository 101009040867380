import React, { useCallback, useState } from "react";
import { Image, ScrollView, TouchableOpacity } from "react-native";
import {
  Avatar,
  BottomModal,
  BottomPanel,
  Box,
  ButtonIcon,
  ButtonMain,
  Text,
} from "../../../../components";
import { useAuthentication } from "../../../../hooks";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../services";
import { EmailAuthModal } from "../../../auth/screens/EmailAuthModal/EmailAuthModal";
import { useMutation } from "@tanstack/react-query";
import { Api } from "../../../../api";

const zeppelinLogo = require("../../../../assets/zeppelin_logo.png");

export function SignUpOfferLanding() {
  const navigate = useNavigate();

  const { isLoaded, userLoggedIn, isAnonimous } = useAuthentication();
  const isUserAuthenticated = isLoaded && userLoggedIn && !isAnonimous;

  const currentUserProfilePhoto = auth.currentUser?.photoURL || null;

  const [showEmailAuth, setShowEmailAuth] = useState(false);

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = useCallback(() => {
    setShowMenu(true);
  }, []);

  const closeMenu = useCallback(() => {
    setShowMenu(false);
  }, []);

  const postLogInHandler = useCallback(() => {
    navigate("/user/subscriptions");
  }, [navigate]);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => Api.postSignUpOffer(),
    onSuccess: () => {
      navigate("/download-app");
    },
  });

  const postSignUpHandler = () => {
    closeMenu();
    setShowEmailAuth(false);
    mutate();
  };

  return (
    <>
      <Box flex={1} backgroundColor="backgroundPrimary">
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <Box
            paddingHorizontal="m"
            paddingTop="l"
            backgroundColor="backgroundLightPrimary"
            alignItems="center"
          >
            {!currentUserProfilePhoto?.length ? (
              <ButtonIcon
                icon="user"
                backgroundColor="backgroundSecondary"
                onPress={openMenu}
                position="absolute"
                top={20}
                right={15}
              />
            ) : (
              <TouchableOpacity
                onPress={openMenu}
                style={{ position: "absolute", top: 20, right: 15 }}
              >
                <Avatar photo={currentUserProfilePhoto} variant="large" />
              </TouchableOpacity>
            )}
            <Image
              source={zeppelinLogo}
              style={{ height: 28, width: "100%" }}
              resizeMode="contain"
            />
            <Text
              variant="h1"
              fontSize={18}
              fontWeight="500"
              marginTop="xl"
              paddingTop="xl"
              textAlign="center"
            >
              Sign up to the
            </Text>
            <Text variant="h1" fontSize={42} textAlign="center" paddingTop="s">
              Zeppelin App
            </Text>
            <Text
              variant="body"
              fontWeight="300"
              fontSize={22}
              color="textSubdued"
              paddingTop="m"
              textAlign="center"
            >
              And get a free pint on us at Mezzanine
            </Text>

            <Box
              marginTop="m"
              width="100%"
              paddingHorizontal="m"
              paddingVertical="l"
              borderRadius="xl"
              backgroundColor="backgroundContrast"
              justifyContent="center"
              alignItems="center"
              bottom={-20}
            >
              <Text variant="h1" color="textAlt">
                Get a free pint on us
              </Text>
              <Text
                variant="h2"
                color="textAlt"
                fontWeight="300"
                marginTop="m"
                textAlign="center"
              >
                at Mezzanine
              </Text>
            </Box>
          </Box>

          <Box paddingHorizontal="m" paddingVertical="xl" alignItems="center">
            <Text
              variant="body"
              fontSize={15}
              color="textSubdued"
              textAlign="center"
              paddingTop="m"
            >
              Hit the Join now for free button and download the Zeppelin app.
            </Text>
            <Text
              variant="body"
              fontSize={12}
              color="textSubdued"
              paddingTop="l"
            >
              Offer ronly valid while stock lasts.
            </Text>
          </Box>
        </ScrollView>

        <EmailAuthModal
          visible={showEmailAuth}
          onClose={() => setShowEmailAuth(false)}
          onPostLogIn={postLogInHandler}
          onPostSignUp={postSignUpHandler}
        />

        <BottomPanel>
          <ButtonMain
            label={!isUserAuthenticated ? "Join now for free" : "My wallet"}
            loading={isLoading}
            onPress={() => {
              isUserAuthenticated
                ? navigate("/user/subscriptions")
                : setShowEmailAuth(true);
            }}
          />
          {!!isUserAuthenticated && (
            <ButtonMain
              color="textPrimary"
              backgroundColor="backgroundSecondary"
              label="My subscriptions"
              marginTop="xxs"
              onPress={() => {
                navigate("/user/subscriptions");
              }}
            />
          )}
        </BottomPanel>
      </Box>

      {isUserAuthenticated ? (
        <BottomModal open={showMenu} onCloseModal={closeMenu}>
          <Box paddingBottom="l">
            <TouchableOpacity
              onPress={() => {
                navigate("/user/feed");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Feed
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/subscriptions");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Subscriptions
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("/user/account");
                setShowMenu(false);
              }}
            >
              <Text variant="h3" paddingVertical="m" fontWeight="500">
                Account
              </Text>
            </TouchableOpacity>
          </Box>
        </BottomModal>
      ) : (
        <EmailAuthModal
          visible={showMenu}
          onClose={closeMenu}
          onPostLogIn={postLogInHandler}
          onPostSignUp={postSignUpHandler}
        />
      )}
    </>
  );
}
