import React from "react";
import Box from "./Box";
import { TouchableOpacity } from "react-native";
import { Home, CreditCard } from "react-feather";
import Text from "./Text";
import { useNavigate } from "react-router-dom";

interface BottomTabBarProps {
  activeTab: "feed" | "subscriptions";
}

const ACTIVE_TAB_COLOR = "black";
const INACTIVE_TAB_COLOR = "#999999";

const BottomTabBar = ({ activeTab }: BottomTabBarProps) => {
  const navigate = useNavigate();

  return (
    <Box
      backgroundColor="backgroundPrimary"
      borderTopWidth={1}
      borderColor="layoutDivider"
      flexDirection="row"
    >
      <TouchableOpacity
        onPress={() => navigate("/user/feed")}
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 16,
          paddingBottom: 20,
        }}
      >
        <Home
          size={20}
          color={activeTab === "feed" ? ACTIVE_TAB_COLOR : INACTIVE_TAB_COLOR}
        />
        <Text
          variant="body"
          fontSize={12}
          paddingTop="xxs"
          style={{
            color: activeTab === "feed" ? ACTIVE_TAB_COLOR : INACTIVE_TAB_COLOR,
          }}
        >
          Feed
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => navigate("/user/subscriptions")}
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 16,
          paddingBottom: 20,
        }}
      >
        <CreditCard
          size={20}
          color={
            activeTab === "subscriptions"
              ? ACTIVE_TAB_COLOR
              : INACTIVE_TAB_COLOR
          }
        />
        <Text
          variant="body"
          fontSize={12}
          paddingTop="xxs"
          style={{
            color:
              activeTab === "subscriptions"
                ? ACTIVE_TAB_COLOR
                : INACTIVE_TAB_COLOR,
          }}
        >
          Subscriptions
        </Text>
      </TouchableOpacity>
    </Box>
  );
};

export default BottomTabBar;
