import { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  MenuItemOption,
  MenuItemOptionValue,
  OrderItem,
  OrderItemOptionValue,
  OrderItemStatus,
  UserType,
} from "@beinatlanda/zeppelin-shared";

import { useOfferingItemDetails } from "../../../../hooks";

import { auth } from "../../../../services";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalOrder } from "../../../../hooks/useLocalOrder";

function useOfferingItemDetailsScreen() {
  const navigate = useNavigate();

  const { itemId, vendorId } = useParams();

  const { order, addItemToLocalOrder } = useLocalOrder(vendorId);

  const { itemDetails } = useOfferingItemDetails(itemId, vendorId);

  const [selectedOptionValues, setSelectedOptionValues] = useState<
    OrderItemOptionValue[]
  >([]);
  const [count, setCount] = useState(1);

  const optionChangeHandler = useCallback(
    (orderOption: MenuItemOption, value: MenuItemOptionValue) => {
      const { id: optionId, name: optionName, multipleChoice } = orderOption;

      const newOptionValue = {
        ...value,
        option: { id: optionId, name: optionName, multipleChoice },
      } as OrderItemOptionValue;

      let updatedOptionValues = [...selectedOptionValues];

      // Replace remove the previous value if the option is not multiple choice
      if (!multipleChoice) {
        updatedOptionValues = updatedOptionValues.filter(
          (item) => item.option.id !== optionId
        );
      }

      if (updatedOptionValues.find((i) => i.id === value.id)) {
        updatedOptionValues = updatedOptionValues.filter(
          (item) => item.id !== value.id
        );
      } else {
        updatedOptionValues.push(newOptionValue);
      }

      setSelectedOptionValues(updatedOptionValues);
    },
    [selectedOptionValues]
  );

  const countChangeHandler = useCallback((value: number) => {
    setCount(value);
  }, []);

  const optionsAddedCost = useMemo(
    () =>
      selectedOptionValues.reduce((acc, cur) => {
        if (!cur.cost) return acc;
        return acc + cur.cost;
      }, 0),
    [selectedOptionValues]
  );

  const itemInstancePrice = useMemo(() => {
    if (!itemDetails || !itemDetails.price) return 0;
    return (
      (itemDetails.discountedPrice || itemDetails.price) + optionsAddedCost
    );
  }, [itemDetails, optionsAddedCost]);

  const createUniqueItemInstanceId = useCallback(() => {
    if (!itemDetails) return "";

    const optionValuesString = selectedOptionValues
      .sort()
      .reduce((acc, curr) => `${acc}-${curr.id}`, "");

    return itemDetails.id + optionValuesString;
  }, [itemDetails, selectedOptionValues]);

  const addToOrderHandler = useCallback(async () => {
    const currentUser = auth.currentUser;

    if (!itemDetails || !order || !currentUser) return;

    const {
      id,
      name,
      category,
      price,
      discountedPrice,
      currencyCode,
      image,
      destination,
    } = itemDetails;

    const uniqueItemInstanceId = createUniqueItemInstanceId();

    const newOrderItem: OrderItem = {
      id: uuidv4(),
      itemId: id,
      uniqueItemInstanceId,
      status: OrderItemStatus.userSelected,
      offeringItem: itemDetails,
      name,
      category,
      image,
      price: discountedPrice || price,
      instancePrice: itemInstancePrice,
      totalPrice: itemInstancePrice * count,
      currencyCode,
      orderedBy: {
        id: currentUser?.uid,
        name: currentUser?.displayName,
        photo: currentUser?.photoURL,
        userType: UserType.customer,
      },
      count,
      ...(destination && { destination }),
      options: selectedOptionValues,
    };

    // Orders.addUserSelectedItemToOrder(order.id, newOrderItem);
    addItemToLocalOrder(newOrderItem);
    navigate(-1);
  }, [
    itemDetails,
    order,
    createUniqueItemInstanceId,
    itemInstancePrice,
    count,
    selectedOptionValues,
    addItemToLocalOrder,
    navigate,
  ]);

  return {
    itemDetails,
    selectedOptionValues,
    optionChangeHandler,
    count,
    countChangeHandler,
    itemInstancePrice,
    addToOrderHandler,
    isOrderOpen: !!order,
  };
}

export default useOfferingItemDetailsScreen;
