import React, { forwardRef, Ref } from "react";
import {
  TextInput as BasicTextInput,
  TextInputProps as RNTextInputProps,
} from "react-native";
import {
  useRestyle,
  spacing,
  layout,
  border,
  backgroundColor,
  LayoutProps,
  SpacingProps,
  BorderProps,
  BackgroundColorProps,
} from "@shopify/restyle";

import { Theme } from "../theme";

const containerRestyleFunctions = [spacing, layout, backgroundColor, border];

type TextInputProps = RNTextInputProps &
  SpacingProps<Theme> &
  BorderProps<Theme> &
  BackgroundColorProps<Theme> &
  LayoutProps<Theme> & {
    showError?: boolean;
  };

const TextInput = forwardRef(
  (props: TextInputProps, ref: Ref<BasicTextInput>) => {
    const { ...rest } = props;

    const containerProps = useRestyle(containerRestyleFunctions, {
      ...rest,
      style: { fontSize: 16 },
    });

    return <BasicTextInput ref={ref} {...rest} {...containerProps} />;
  }
);

export default React.memo(TextInput);
