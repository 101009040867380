import { Api } from "../../../api";
import { useQuery } from "@tanstack/react-query";

export const useVendorSubscriptions = (
  vendorId: string,
  customerUserId?: string
) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["vendor-products-v2", customerUserId],
    enabled: !!vendorId,
    queryFn: () => {
      return Api.getVendorProducts({ vendorId, customerUserId });
    },
  });

  return { data, isLoading, error };
};
