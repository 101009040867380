import { useCallback } from "react";
import { ScrollView } from "react-native";
import { useTheme } from "@shopify/restyle";

import {
  AdditionalCharges,
  BottomPanel,
  ButtonMain,
  Divider,
  Header,
  OrderItemRow,
  SectionHeader,
  Text,
  TextInput,
} from "../../../../components";

import useCheckoutScreen from "./useCheckoutScreen";

import { Theme } from "../../../../theme";
import { useNavigate } from "react-router-dom";
import { TableSelectionModal } from "../../components/TableSelectionModal/TableSelectionModal";
import { TablePicker } from "../../components/TablePicker/TablePicker";
import { PaymentFormModal } from "../../components/PaymentFormModal/PaymentFormModa";

export function Checkout() {
  const navigate = useNavigate();

  const {
    itemsSubtotalAmount,
    serviceChargePercentage,
    serviceChargeAmount,
    processingChargeAmount,
    discountPercentage,
    discountTotalAmount,
    orderTotal,
    showFullOrderList,
    toggleSeeFullOrder,
    orderItems,
    onPayHandler,
    paymentSetupInProgress,
    clientSecret,
    email,
    onEmailChange,
    table,
    onTableChange,
    paymentSuccessUrl,
    dismissPaymentClientSecret,
  } = useCheckoutScreen();

  const { colors } = useTheme<Theme>();

  const backHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Header title="Checkout" onBackPress={backHandler} />
      <ScrollView
        style={{ backgroundColor: colors.backgroundPrimary }}
        contentContainerStyle={{ flexGrow: 1 }}
        alwaysBounceVertical={false}
      >
        <SectionHeader title="Table*" />
        <TablePicker
          value={table}
          onChange={onTableChange}
          placeholder="Select table"
        />

        <TableSelectionModal
          showModal={false}
          onChange={(table) => {
            console.log({ table });
          }}
          onClose={() => {}}
        />
        <SectionHeader title="Email*" />
        <TextInput
          value={email}
          onChangeText={onEmailChange}
          placeholder="Enter email"
          placeholderTextColor="#808080"
          borderRadius="m"
          padding="m"
          borderColor="layoutDivider"
          backgroundColor="backgroundSecondary"
          borderWidth={1}
          marginHorizontal="m"
          margin="m"
        />
        <SectionHeader title="Order items" />
        {orderItems?.map((item, index) => {
          return (
            <>
              {index !== 0 && <Divider key={index} width="80%" />}
              <OrderItemRow key={item.id} item={item} />
            </>
          );
        })}
        <Divider />
        <Text
          variant="h5"
          color="primary"
          textAlign="center"
          paddingVertical="s"
          paddingHorizontal="m"
          onPress={toggleSeeFullOrder}
        >
          {showFullOrderList ? "Show less" : "See all"}
        </Text>
        <AdditionalCharges
          subtotal={itemsSubtotalAmount}
          discountPercentage={discountPercentage}
          discountTotalAmount={discountTotalAmount}
          serviceCharge={serviceChargeAmount}
          serviceChargePercentage={serviceChargePercentage}
          processingCost={processingChargeAmount}
        />
      </ScrollView>
      <BottomPanel
        content={{
          key: "Order total",
          value: `£${orderTotal && (orderTotal / 100).toFixed(2)}`,
        }}
      >
        <ButtonMain
          label="Payment"
          onPress={onPayHandler}
          loading={paymentSetupInProgress}
        />
      </BottomPanel>
      {clientSecret && (
        <PaymentFormModal
          key={"1"}
          clientSecret={clientSecret}
          successUrl={paymentSuccessUrl}
          closeModalHandler={dismissPaymentClientSecret}
        />
      )}
    </>
  );
}
