import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { GuestVendor, GuestVendors } from "@beinatlanda/zeppelin-shared";
import { Box, Divider, Text } from "../../../../components";
import { FlatList, ListRenderItem, TouchableOpacity } from "react-native";
import { GuestVendorListItem } from "./GuestVendorListItem";

interface GuestVendorsDisplayProps {
  guestVendorsData?: GuestVendors;
  linkToVendor: (vendor: GuestVendor) => void;
}

export const GuestVendorsDisplay = ({
  guestVendorsData,
  linkToVendor,
}: GuestVendorsDisplayProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!guestVendorsData?.isActive) return null;

  const renderVendor: ListRenderItem<GuestVendor> = ({ item }) => {
    return <GuestVendorListItem vendor={item} onPress={linkToVendor} />;
  };

  return (
    <Box
      backgroundColor="backgroundLightPrimary"
      marginTop="m"
      marginBottom="s"
      padding="m"
      borderRadius="m"
    >
      <TouchableOpacity onPress={() => setIsExpanded((val) => !val)}>
        <Box flexDirection="row" justifyContent="center" alignItems="center">
          <Text variant="h6" paddingRight="xs">
            🍔 Order food from market vendors
          </Text>
          {isExpanded ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
        </Box>
      </TouchableOpacity>
      {isExpanded && (
        <>
          <Divider marginTop="m" marginBottom="s" />
          <Text variant="h3">Order food from market vendors</Text>
          <Text variant="body" color="textSubdued" marginBottom="m">
            The food you order will be delivered to your table
          </Text>

          <FlatList
            data={guestVendorsData.vendors}
            renderItem={renderVendor}
            keyExtractor={(vendor) => vendor.id}
            scrollEnabled={false}
            ItemSeparatorComponent={() => <Divider marginVertical="xs" />}
          />
        </>
      )}
    </Box>
  );
};
