import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../modules/home/screens/Home";
import {
  OrderVendorDetails,
  OfferingItemDetails,
  Order,
  Checkout,
  PaymentSuccess,
} from "../modules/tableOrder";
import {
  SubscriptionDetails,
  MembershipProofDisplay,
  Subscriptions,
  ZeppelinMembershipLanding,
} from "../modules/subscriptions";
import { useAuthentication } from "../hooks";
import Feed from "../modules/feed/screens/Feed";
import { Account } from "../modules/account/screens/Account";
import { ZeppelinMembershipDetails } from "../modules/subscriptions/screens/ZeppelinMembershipDetails/ZeppelinMembershipDetails";
import { SubscriptionProofDisplay } from "../modules/subscriptions/screens/SubscriptionProofDisplay/SubscriptionProofDisplay";
import { SubscriptionLanding } from "../modules/subscriptions/screens/SubscriptionLanding/SubscriptionLanding";
import { VendorSubscriptionOffering } from "../modules/subscriptions/screens/VendorSubscriptionOffering/VendorSubscriptionOffering";
import { BulkProductLanding } from "../modules/subscriptions/screens/BulkProductLanding/BulkProductLanding";
import { BulkPurchaseDetails } from "../modules/subscriptions/screens/BulkPurchaseDetails/BulkPurchaseDetails";
import { BulkProductPurchaseProofDisplay } from "../modules/subscriptions/screens/BulkProductPurchaseProofDisplay/BulkProductPurchaseProofDisplay";
import { SignUpOfferLanding } from "../modules/subscriptions/screens/SignUpOfferLanding/SignUpOfferLanding";
import { DownloadApp } from "../modules/subscriptions/screens/DownloadApp/DownloadApp";

export const Navigator = () => {
  useAuthentication();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/user/account" element={<Account />} />
        <Route path="/user/feed" element={<Feed />} />
        <Route path="/user/subscriptions" element={<Subscriptions />} />
        <Route
          path="/user/membership-details"
          element={<ZeppelinMembershipDetails />}
        />
        <Route
          path="/user/membership-proof"
          element={<MembershipProofDisplay />}
        />
        <Route
          path="/zeppelin-membership"
          element={<ZeppelinMembershipLanding />}
        />
        <Route
          path="/subscription-landing/:vendorId/:productId"
          element={<SubscriptionLanding />}
        />
        <Route
          path="/bulk-product-landing/:vendorId/:productId"
          element={<BulkProductLanding />}
        />
        <Route
          path="/user/bulk-purchase/:bulkPurchaseId"
          element={<BulkPurchaseDetails />}
        />
        <Route
          path="/user/subscription-details/:subscriptionId"
          element={<SubscriptionDetails />}
        />
        <Route
          path="/user/subscription-proof/:subscriptionId"
          element={<SubscriptionProofDisplay />}
        />
        <Route
          path="/user/bulk-product-purchase-proof/:bulkPurchaseId"
          element={<BulkProductPurchaseProofDisplay />}
        />
        <Route
          path="/subscription-offering/:vendorId"
          element={<VendorSubscriptionOffering />}
        />
        <Route path="/table-order/:vendorId" element={<OrderVendorDetails />} />
        <Route
          path="/table-order/:vendorId/:itemId"
          element={<OfferingItemDetails />}
        />
        <Route path="/table-order/:vendorId/order" element={<Order />} />
        <Route path="/table-order/:vendorId/checkout" element={<Checkout />} />
        <Route
          path="/table-order/:vendorId/payment-success/:orderId"
          element={<PaymentSuccess />}
        />
        <Route path="/sign-up-offer" element={<SignUpOfferLanding />} />
        <Route path="/download-app" element={<DownloadApp />} />
      </Routes>
    </BrowserRouter>
  );
};
