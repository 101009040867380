import React from "react";
import { Box, Text } from "../../../components";
import { useVendorSubscriptions } from "../hooks/useVendorSubscriptions";
import { ActivityIndicator, TouchableOpacity, Image } from "react-native";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../hooks";

interface VendorProductsListProps {
  vendorId: string;
}

export const VendorProductsList = ({ vendorId }: VendorProductsListProps) => {
  const navigate = useNavigate();

  const { userLoggedIn, isAnonimous, userId } = useAuthentication();

  const { data, isLoading, error } = useVendorSubscriptions(
    vendorId,
    userLoggedIn && !isAnonimous ? userId : undefined
  );

  return (
    <Box>
      {!!data?.subscriptions?.length && <Text variant="h5">Subscriptions</Text>}
      {data?.subscriptions?.map((subscription) => {
        const { images, description, name, id, activeSubscription } =
          subscription;

        return (
          <TouchableOpacity
            key={id}
            onPress={() => {
              navigate(
                activeSubscription
                  ? `/user/subscriptions`
                  : `/subscription-landing/${vendorId}/${id}`
              );
            }}
          >
            <Box
              padding="m"
              borderWidth={1}
              borderColor="layoutDivider"
              borderRadius="m"
              backgroundColor="backgroundSecondary"
              flexDirection="row"
              marginTop="m"
            >
              {images?.[0] && (
                <Image
                  source={{ uri: images[0] }}
                  style={{ height: 100, width: 100, borderRadius: 12 }}
                />
              )}

              <Box flex={1} paddingLeft="s" paddingTop="xs">
                <Text variant="h3">{name}</Text>
                <Text variant="body" color="textSubdued">
                  {description}
                </Text>
                {!!activeSubscription && (
                  <Text variant="h5" color="primary" paddingTop="xs">
                    SUBSCRIBED
                  </Text>
                )}
              </Box>
            </Box>
          </TouchableOpacity>
        );
      })}

      {!data?.subscriptions?.length &&
        !data?.bulkProducts?.length &&
        !isLoading && (
          <Text variant="body" color="textSubdued" paddingTop="xl">
            No subscriptions available
          </Text>
        )}

      {!!data?.bulkProducts?.length && (
        <Text variant="h5" marginTop="m">
          Bulk products
        </Text>
      )}
      {data?.bulkProducts?.map((product) => {
        const { name, description, photo, id } = product;

        return (
          <TouchableOpacity
            key={id}
            onPress={() => {
              navigate(`/bulk-product-landing/${vendorId}/${id}`);
            }}
          >
            <Box
              padding="m"
              borderWidth={1}
              borderColor="layoutDivider"
              borderRadius="m"
              backgroundColor="backgroundSecondary"
              flexDirection="row"
              marginTop="m"
            >
              {!!photo && (
                <Image
                  source={{ uri: photo }}
                  style={{ height: 100, width: 100, borderRadius: 12 }}
                />
              )}

              <Box flex={1} paddingLeft="s" paddingTop="xs">
                <Text variant="h3">{name}</Text>
                <Text variant="body" color="textSubdued">
                  {description}
                </Text>
              </Box>
            </Box>
          </TouchableOpacity>
        );
      })}

      {!data?.subscriptions?.length && !isLoading && (
        <Text variant="body" color="textSubdued" paddingTop="xl">
          No bulk products available
        </Text>
      )}

      {isLoading && (
        <Box>
          <ActivityIndicator />
        </Box>
      )}

      {!!error && (
        <Text variant="body" color="danger">
          Something went wrong, please try again
        </Text>
      )}
    </Box>
  );
};
