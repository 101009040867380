import React from "react";
import { Image, TouchableOpacity } from "react-native";
import { GuestVendor } from "@beinatlanda/zeppelin-shared";
import { Box, Text } from "../../../../components";

interface GuestVendorListItemProps {
  vendor?: GuestVendor;
  onPress: (vendor: GuestVendor) => void;
}

export const GuestVendorListItem = ({
  vendor,
  onPress,
}: GuestVendorListItemProps) => {
  if (!vendor?.isAvailable) return null;

  return (
    <TouchableOpacity
      onPress={() => {
        onPress(vendor);
      }}
    >
      <Box paddingVertical="xs" flexDirection="row">
        <Image
          style={{ width: 70, height: 70, borderRadius: 5 }}
          source={{
            uri: vendor.photo,
          }}
          resizeMode="cover"
        />
        <Box flex={1} marginLeft="s" flexDirection="column" flexWrap="wrap">
          <Text variant="h4" marginBottom="xs">
            {vendor.name}
          </Text>
          <Text variant="body" color="textSubdued">
            {vendor.description}
          </Text>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
