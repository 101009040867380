import { useTheme } from "@shopify/restyle";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { memo, useState } from "react";
import { TouchableOpacity } from "react-native";
import { stripePromise } from "../../../../App";
import { Box, ButtonIcon, ButtonMain, Text } from "../../../../components";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { Theme } from "../../../../theme";

interface PaymentFormModalProps {
  clientSecret: string;
  successUrl: string;
  closeModalHandler: () => void;
}

const InnerPaymentForm = (props: PaymentFormModalProps) => {
  const { successUrl, closeModalHandler } = props;

  const { colors } = useTheme<Theme>();

  const { height } = useWindowDimensions();

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //   useEffect(() => {
  //     if (!stripe) {
  //       return;
  //     }

  //     if (!clientSecret) {
  //       return;
  //     }

  //     stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //       switch (paymentIntent.status) {
  //         case "succeeded":
  //           setMessage("Payment succeeded!");
  //           break;
  //         case "processing":
  //           setMessage("Your payment is processing.");
  //           break;
  //         case "requires_payment_method":
  //           setMessage("Your payment was not successful, please try again.");
  //           break;
  //         default:
  //           setMessage("Something went wrong.");
  //           break;
  //       }
  //     });
  //   }, [clientSecret, stripe]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: successUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      window.alert(error.message);
    } else {
      setMessage("An unexpected error occurred.");
      window.alert("An unexpected error occurred.");
    }

    if (!error) {
      console.log("Payment success");
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        height: height,
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
      }}
    >
      <Box flex={1} justifyContent="flex-end">
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: colors.modalBackground,
          }}
          onPress={closeModalHandler}
        />
        <Box
          backgroundColor="backgroundPrimary"
          borderTopLeftRadius="l"
          borderTopRightRadius="l"
          paddingTop="m"
          paddingBottom="s"
          paddingHorizontal="m"
          maxHeight="90vh"
        >
          <ButtonIcon
            icon="x"
            alignSelf="flex-end"
            onPress={closeModalHandler}
          />
          <form>
            <PaymentElement id="payment-element" options={{ layout: "auto" }} />
            <ButtonMain
              onPress={() => {
                handleSubmit();
              }}
              label="Pay"
              loading={isLoading || !stripe || !elements}
              marginTop="m"
              marginBottom="xs"
            />
            {message && (
              <Text variant="h5" color="danger">
                {message}
              </Text>
            )}
          </form>
        </Box>
      </Box>
    </div>
  );
};

export const PaymentFormModal = memo(
  (props: PaymentFormModalProps) => {
    const { clientSecret } = props;

    if (!clientSecret) return null;

    return (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <InnerPaymentForm {...props} />
      </Elements>
    );
  },
  () => true
);
