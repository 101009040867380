import {
  orderToAmountBreakdowns,
  PaymentMetadata,
  PaymentType,
  Table,
} from "@beinatlanda/zeppelin-shared";
import { httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useLocalOrder } from "../../../../hooks/useLocalOrder";
import { auth, functions, Orders } from "../../../../services";
// import useUser from "../../../../hooks/useUser";

function useCheckoutScreen() {
  const { vendorId } = useParams();
  const { order } = useLocalOrder(vendorId);
  // const { user } = useUser(auth.currentUser?.uid);
  // const vendorOffersZeppelinMembership = order.vendor?.offersZeppelinMembership;

  const navigate = useNavigate();

  const getInitialTableValue = () => {
    const storedTable = window.sessionStorage.getItem(`${vendorId}-table`);
    return storedTable ? JSON.parse(storedTable) : null;
  };

  const [showFullOrderList, setShowFullOrderList] = useState(false);
  const [paymentSetupInProgress, setPaymentSetupInProgress] = useState(false);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [email, setEmail] = useState<string>(auth.currentUser?.email || "");
  const [table, setTable] = useState<Table | null>(getInitialTableValue);

  // useEffect(() => {
  //   if (
  //     user?.activeSubscriptions.includes("zeppelin-membership") &&
  //     !order.discountPercentage &&
  //     vendorOffersZeppelinMembership
  //   ) {
  //     addZeppelinMembershipDiscountToLocalOrder();
  //   }
  // }, [
  //   user,
  //   order,
  //   vendorOffersZeppelinMembership,
  //   addZeppelinMembershipDiscountToLocalOrder,
  // ]);

  useEffect(() => {
    if (!order) {
      navigate(`/table-order/${vendorId}`, { replace: true });
    }
  }, [navigate, order, vendorId]);

  const onEmailChange = useCallback((e: string) => {
    setEmail(e);
  }, []);

  const onTableChange = useCallback(
    (t: Table) => {
      window.sessionStorage.setItem(`${vendorId}-table`, JSON.stringify(t));
      setTable(t);
    },
    [vendorId]
  );

  const toggleSeeFullOrder = useCallback(() => {
    setShowFullOrderList(!showFullOrderList);
  }, [showFullOrderList]);

  const {
    itemsSubtotalAmount,
    orderTotalAmount,
    discountPercentage,
    discountTotalAmount,
    serviceChargeAmount,
    serviceChargePercentage,
    processingChargeAmount,
  } = orderToAmountBreakdowns({ ...order, id: "temp_id" });

  const orderTotal = orderTotalAmount + processingChargeAmount;

  const orderItems = showFullOrderList
    ? order?.items
    : order?.items.slice(0, 3);

  const paymentSuccessUrl = `${window.location.origin}/table-order/${vendorId}/payment-success/${orderId}`;

  const dismissPaymentClientSecret = useCallback(() => {
    setClientSecret(null);
  }, []);

  const onPayHandler = async () => {
    setPaymentSetupInProgress(true);

    if (!auth.currentUser) {
      window.alert("Missing user information");
      return;
    }

    if (!email.length || !table) {
      window.alert("The email and table fields are required");
      setPaymentSetupInProgress(false);
      return;
    }

    // auth.updateCurrentUser({ ...auth.currentUser, email });

    // @ts-ignore
    const createOrderResponse = await Orders.createNewOrderGivenData({
      ...order,
      tableId: table.id,
      tableName: table.name,
    });
    const dbOrderId = createOrderResponse.id;
    setOrderId(dbOrderId);

    const paymentMetadata: PaymentMetadata = {
      id: uuidv4(),
      paymentType: PaymentType.Web,
      createdAt: new Date().toISOString(),
      vendorId: order?.vendor?.id || null,
      referrerVendorId: order?.referrerVendorId || null,
      vendorName: order?.vendor?.name || null,
      vendorPhoto: order?.vendor?.photo || null,
      userId: auth.currentUser.uid || null,
      // Sum of all order item costs
      orderSubtotal: itemsSubtotalAmount,
      // Order amount taking splits into account
      orderAmount: itemsSubtotalAmount,
      // Amount of the order paid (cost of items + service) taking splits into account
      orderPaymentAmount: orderTotal,
      // Total amount paid, order + tip
      totalPaymentAmount: orderTotal,
      serviceChargePercentage,
      serviceChargeAmount: serviceChargeAmount || null,
      processingCharge: processingChargeAmount,
      currencyCode: "GBP",
      orderId: dbOrderId || null,
      userName: "",
      // @ts-ignore
      userEmail: email,
      userPhoto: "",
      splitId: "",
      splitItemId: "",
      splitAmount: 0,
      tipAmount: 0,
      tipPercentage: 0,
      discountPercentage: order.discountPercentage,
    };

    try {
      const response = await httpsCallable(
        functions,
        "createPaymentIntentV1"
      )({
        paymentData: paymentMetadata,
      });
      console.log({ response });
      const {
        paymentIntentClientSecret,
        paymentIntentId,
        customerId,
        ephemeralKey,
      } = response.data as any;

      console.log({
        paymentIntentClientSecret,
        paymentIntentId,
        customerId,
        ephemeralKey,
      });

      setClientSecret(paymentIntentClientSecret);

      // if (error) {
      //   window.alert(
      //     'Something went wrong when trying to process your payment. Please try again.',
      //   )
      //   setPaymentSetupInProgress(false)
      //   console.log('init payment sheet error', error.message)
      //   return
      // }

      setPaymentSetupInProgress(false);
    } catch (error) {
      setPaymentSetupInProgress(false);
      console.log("createPaymentIntentV1 error", error);
    }
  };

  return {
    showFullOrderList,
    toggleSeeFullOrder,
    itemsSubtotalAmount,
    serviceChargePercentage,
    serviceChargeAmount,
    processingChargeAmount,
    discountPercentage,
    discountTotalAmount,
    orderTotal,
    orderItems,
    onPayHandler,
    paymentSetupInProgress,
    clientSecret,
    email,
    onEmailChange,
    table,
    onTableChange,
    paymentSuccessUrl,
    dismissPaymentClientSecret,
  };
}

export default useCheckoutScreen;
