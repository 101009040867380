import { useState, useEffect } from "react";
import { auth } from "../services";

function useAuthentication() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isAnonimous, setIsAnonimous] = useState(false);

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged((newUser) => {
      setUserLoggedIn(!!newUser);
      setIsAnonimous(!!newUser?.isAnonymous);
      setIsLoaded(true);
    });
    return subscriber;
  }, []);

  return {
    isLoaded,
    userLoggedIn,
    isAnonimous,
    userId: auth.currentUser?.uid,
    userName: auth.currentUser?.displayName,
    userEmail: auth.currentUser?.email,
  };
}

export default useAuthentication;
