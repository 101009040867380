import React from 'react'
import { TouchableOpacity } from 'react-native'

import { Box, Checkbox, Radio, Text } from '.'

interface OptionListRowProps {
  name: string
  desctiption?: string
  price?: number
  isMultipleChoice?: boolean
  active: boolean
  onPress: () => void
}

function OptionListRow(props: OptionListRowProps) {
  const { name, desctiption, price, isMultipleChoice, active, onPress } = props

  return (
    <TouchableOpacity onPress={onPress}>
      <Box padding="m" backgroundColor="backgroundPrimary" flexDirection="row">
        {isMultipleChoice ? <Checkbox active={active} /> : <Radio active={active} />}
        <Box flex={1} paddingLeft="m">
          <Box flexDirection="row" justifyContent="space-between">
            <Text variant="h6" paddingRight="s">
              {name}
            </Text>
            {!!price && <Text variant="body" color="textSubdued">{`+ £${(price / 100).toFixed(2)}`}</Text>}
          </Box>
          {!!desctiption && (
            <Text variant="body" color="textSubdued">
              {desctiption}
            </Text>
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  )
}

export default React.memo(OptionListRow)
