import React from 'react'
import { Box, Text } from '.'

import content from '../content'

interface SectionHeaderProps {
  title: string
  description?: string
  required?: boolean
}

function SectionHeader(props: SectionHeaderProps) {
  const { title, description, required } = props

  return (
    <Box
      paddingHorizontal="m"
      paddingVertical="m"
      backgroundColor="backgroundSecondary"
      borderTopWidth={1}
      borderBottomWidth={1}
      borderColor="layoutDivider">
      <Text variant="h3" numberOfLines={2}>
        {title}
        {required && (
          <Text variant="h5" color="primary">
            {`  ${content.required}`}
          </Text>
        )}
      </Text>
      {!!description && (
        <Text variant="body" color="textSubdued" paddingTop="xxs" numberOfLines={2}>
          {description}
        </Text>
      )}
    </Box>
  )
}

export default React.memo(SectionHeader)
