import React, { useCallback } from "react";
import { ScrollView } from "react-native";

import { useZeppelinMembership } from "../../hooks/useZeppelinMembership";
import { useAppTheme } from "../../../../theme";
import { Box, ButtonMain, Header, Text } from "../../../../components";
import { useNavigate } from "react-router-dom";

const zeppelinTileImage = require("../../../../assets/ZeppelinTile.png");
// const mapImage = require("../../../assets/images/map.png");

export const ZeppelinMembershipDetails = () => {
  const navigate = useNavigate();

  const { colors, spacing } = useAppTheme();

  const { data } = useZeppelinMembership();

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onShowIdentifier = useCallback(() => {
    navigate("/user/membership-proof");
  }, [navigate]);

  return (
    <Box style={{ flex: 1, backgroundColor: colors.backgroundPrimary }}>
      <Header
        onBackPress={goBack}
        transparent
        showBorder={false}
        photo={zeppelinTileImage}
      />
      <ScrollView
        contentContainerStyle={{
          paddingHorizontal: spacing.m,
          paddingTop: spacing.l,
          flexGrow: 1,
        }}
      >
        <Text variant="h1">{data?.name}</Text>
        <Text variant="body" paddingTop="xs">
          {data?.description}
        </Text>

        <Box
          backgroundColor="backgroundSecondary"
          padding="m"
          borderRadius="xl"
          marginTop="xl"
        >
          <Text variant="h3" textAlign="center">
            {`Get ${data?.discountPercentage}% off your order today`}
          </Text>
        </Box>

        {/* <Text variant="h5" marginTop="xl" paddingBottom="xs">
          Locations
        </Text>
        <Box>
          <Image
            source={mapImage}
            style={{ height: 140, width: '100%', borderRadius: 20, borderWidth: 1, borderColor: colors.layoutDivider }}
          />
          <Box position="absolute" top={68} width="100%">
            <Text variant="h6" fontSize={18} textAlign="center" color="textPrimary">
              Show map
            </Text>
          </Box>
        </Box> */}
      </ScrollView>
      <ButtonMain
        label="Show membership proof"
        onPress={onShowIdentifier}
        marginHorizontal="m"
        marginVertical="s"
        backgroundColor="backgroundContrast"
      />
    </Box>
  );
};
